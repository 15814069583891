import React from 'react';
import Navbar from './components/Navbar';
import ResponsiveShadow from './components/ResponsiveShadow';

const PaginaDefault = () => {
  return (
    <div className='full-screen-div'>
      <Navbar />
      <div>
        <ResponsiveShadow className="container mt-4 mb-4">
        </ResponsiveShadow>
      </div>

    </div>

  );
}

export default PaginaDefault;