// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.w-25 {
    width: 25%;
}

.w-50 {
    width: 50%;
}

.w-15 {
    width: 15%;
}

.w-10 {
    width: 10%;
}
/* Define uma largura mínima para campos que não devem encolher */
.input-fixo {
    min-width: 250px; /* Ajuste conforme necessário */
}

/* Ajusta campos para preencher a largura apenas em telas pequenas */
@media (max-width: 576px) {
    .input-100 {
        width: 100% !important;
    }
}

`, "",{"version":3,"sources":["webpack://./src/service/css/Cliente.css"],"names":[],"mappings":"AAAA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;AACd;AACA,iEAAiE;AACjE;IACI,gBAAgB,EAAE,+BAA+B;AACrD;;AAEA,oEAAoE;AACpE;IACI;QACI,sBAAsB;IAC1B;AACJ","sourcesContent":[".w-25 {\r\n    width: 25%;\r\n}\r\n\r\n.w-50 {\r\n    width: 50%;\r\n}\r\n\r\n.w-15 {\r\n    width: 15%;\r\n}\r\n\r\n.w-10 {\r\n    width: 10%;\r\n}\r\n/* Define uma largura mínima para campos que não devem encolher */\r\n.input-fixo {\r\n    min-width: 250px; /* Ajuste conforme necessário */\r\n}\r\n\r\n/* Ajusta campos para preencher a largura apenas em telas pequenas */\r\n@media (max-width: 576px) {\r\n    .input-100 {\r\n        width: 100% !important;\r\n    }\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
