import React from 'react';
import InputMask from 'react-input-mask';

function CEPMaskInput({ value, onChange, ...rest }) {
    return (
        <InputMask
            mask="99.999-999"
            value={value}
            onChange={onChange}
        >
            {(inputProps) => <input {...inputProps} {...rest} className="form-control" />}
        </InputMask>
    );
}

export default CEPMaskInput;
