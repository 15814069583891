import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_BASE_URL } from './ApiConfig';
import ResponsiveShadow from './components/ResponsiveShadow';

function LoginPage() {
    const [login, setLogin] = useState('');
    const [senha, setSenha] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    const handleEmailChange = (event) => setLogin(event.target.value);
    const handlePasswordChange = (event) => setSenha(event.target.value);

    async function handleSubmit(event) {
        event.preventDefault();
        try {
            const response = await axios.post(`${API_BASE_URL.LOGIN}?login=${login}&senha=${senha}`);
            const { token, user, userBind } = response.data;
            localStorage.setItem('token', token);
            localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('userBind', JSON.stringify(userBind));
            navigate('/seleciona_entidade');
        } catch (error) {
            setErrorMessage('Login ou senha incorretos. Tente novamente.');
            console.error('Erro ao buscar itens:', error);
        }
    }

    return (
        <div
            className="d-flex justify-content-center align-items-center"
            style={{ minHeight: '100vh' }}
        >
            <ResponsiveShadow
                className="row align-items-center mx-4 my-4"
                style={{
                    width: '100%',
                    maxWidth: '700px',
                    borderRadius: '10px',
                    overflow: 'hidden',
                }}
            >
                {/* Coluna da Imagem */}
                <div className="col-12 col-md-6 d-flex justify-content-center align-items-center p-3">
                    <img
                        src="/assets/PCA.png"
                        alt="Imagem de login"
                        className="rounded img-fluid"
                        style={{
                            maxWidth: '100%',
                            height: 'auto',
                            maxHeight: '256px',
                        }}
                    />
                </div>

                {/* Coluna do Formulário */}
                <div className="col-12 col-md-6 p-3">
                    <h3 className="text-center mb-3">Login</h3>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-2">
                            <label htmlFor="login" className="form-label">Usuário</label>
                            <input
                                type="text"
                                className="form-control"
                                id="login"
                                value={login}
                                onChange={handleEmailChange}
                                required
                            />
                        </div>
                        <div className="mb-2">
                            <label htmlFor="senha" className="form-label">Senha</label>
                            <input
                                type="password"
                                className="form-control"
                                id="senha"
                                value={senha}
                                onChange={handlePasswordChange}
                                required
                            />
                        </div>
                        {errorMessage && (
                            <div className="alert alert-danger" role="alert">
                                {errorMessage}
                            </div>
                        )}
                        <button type="submit" className="btn btn-primary w-100">Login</button>
                    </form>
                </div>
            </ResponsiveShadow>
        </div>
    );
}

export default LoginPage;
