import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './css/Cliente.css';
import axios from 'axios';
import Navbar from './components/Navbar';
import { API_BASE_URL } from './ApiConfig';
import 'bootstrap-icons/font/bootstrap-icons.css';
import ResponsiveShadow from './components/ResponsiveShadow';

function Listas() {
    const [MatListas, setMatListas] = useState([]);
    const [itemToDelete, setItemToDelete] = useState(null);
    const [shouldFetch, setShouldFetch] = useState(false);
    const token = localStorage.getItem('token');
    const cnpj = localStorage.getItem('cnpj');
    const navigate = useNavigate();

    useEffect(() => {
        axios.get(`${API_BASE_URL.LISTA}/cnpj`, {
            params: {
                cnpj: cnpj
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(result => {
            setMatListas(result.data);
        }).catch(error => {
            console.error('Erro ao obter listas:', error);
            navigate("/");
        });
    }, [shouldFetch]); // Adicionado shouldFetch

    if (!MatListas) {
        return <div>Loading...</div>;
    }

    function handleDelete(lista) {
        setItemToDelete(lista);
        const modal = new window.bootstrap.Modal(document.getElementById('deleteConfirmationModal'));
        modal.show();
    }

    function handleDeleteConfirm() {
        axios.delete(`${API_BASE_URL.LISTA}/delete`, {
            params: {
                cnpj: itemToDelete.cnpj,
                id: itemToDelete.id
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(result => {
            setShouldFetch(!shouldFetch); // Alterna o estado para disparar o useEffect
            setItemToDelete(null);
        })
            .catch(error => {
                console.error('Erro ao excluir o item:', error);
            });
    }

    // Função para formatar a data no formato 'dd/MM/yyyy'
    function formatDateForInput(dateString) {
        if (dateString) {
            const date = new Date(dateString);
            const formattedDate = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;
            return formattedDate;
        }
        return '';
    }

    return (
        <div className='full-screen-div'>
            <Navbar />
            <div className="container mt-4 pt-5">
                <ResponsiveShadow className="container mt-4 mb-4">
                    <main role="main" className="col-md-12 ml-sm-auto col-lg-12 px-md-4">
                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-1 mb-2 border-bottom">
                            <h3 className="h3">Listas de Materiais ou Serviços</h3>
                        </div>
                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-1 pb-2 mb-1">
                            <Link to="/lista" className='btn btn-success btn-sm'>
                                <i className="bi bi-plus-lg" /> Criar Lista
                            </Link>
                        </div>
                        <div className="table-responsive-sm">
                            <table className="table table-hover table-striped table-sm">
                                <thead className="table-secondary">
                                    <tr>
                                        <th scope="DATA">Data</th>
                                        <th scope="DESCRICAO">Descrição</th>
                                        <th scope="TIPO">Tipo</th>
                                        <th scope="ACOES">Ações</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {MatListas.map(c => (
                                        <tr key={c.id}>
                                            <td>{formatDateForInput(c.data)}</td>
                                            <td>{c.descricao}</td>
                                            <td>{c.tipo}</td>
                                            <td>
                                                <div className="btn-groupbtn-group d-flex flex-column flex-md-row" role="group" aria-label="Botões de ação">
                                                    <Link to="/lista" state={{ matLista: c }} className='btn btn-success btn-sm mx-1 my-1'>
                                                        <i className="bi bi-pencil-fill" /> Lista
                                                    </Link>
                                                    <Link to="/lista_itens" state={{ matLista: c }} className='btn btn-primary btn-sm mx-1 my-1'>
                                                        <i className="bi bi-pen-fill" /> Itens
                                                    </Link>
                                                    <button onClick={() => handleDelete(c)} className='btn btn-danger btn-sm mx-1 my-1'>
                                                        <i className="bi bi-trash3" /> Excluir
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </main>
                </ResponsiveShadow>
            </div>
            {/* Modal de Confirmação */}
            <div className="modal fade" id="deleteConfirmationModal" tabIndex="-1" aria-labelledby="deleteConfirmationModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="deleteConfirmationModalLabel">Confirmar Exclusão</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            Tem certeza que deseja excluir este item?
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                            <button type="button" className="btn btn-danger" onClick={handleDeleteConfirm} data-bs-dismiss="modal">Excluir</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Listas;
