import React, { useEffect, useState } from 'react';

function ResponsiveShadow({ children, className = '', style = {} }) {
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        // Cleanup
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div
            className={`${className} ${isSmallScreen ? '' : 'shadow-lg rounded-3'}`}
            style={style}
        >
            {children}
        </div>
    );
}

export default ResponsiveShadow;
