import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './css/Cliente.css';
import axios from 'axios';
import Navbar from './components/Navbar';
import { API_BASE_URL } from './ApiConfig';
import LoadingModal from './components/LoadingModal';
import ErrorModal from './components/ErrorModal';
import Pagination from './components/Pagination';
import ConfirmDeleteModal from './components/ConfirmDeleteModal';
import { formatDateView } from './utils/dateUtils';
import ResponsiveShadow from './components/ResponsiveShadow';

function CatmatDemandas() {
    const [CatmatDfd, setCatmatDfds] = useState([]);
    const [isDeleting, setIsDeleting] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [startIndex, setStartIndex] = useState(0);

    const [modalMessage, setModalMessage] = useState('');
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [itemToDelete, setItemToDelete] = useState(null);

    const token = localStorage.getItem('token');
    const cnpj = localStorage.getItem('cnpj');
    const navigate = useNavigate();

    useEffect(() => {
        setIsLoading(true);
        axios.get(`${API_BASE_URL.CATMAT_DFD}/cnpj`, {
            params: { cnpj },
            headers: { Authorization: `Bearer ${token}` }
        })
            .then(result => {
                setCatmatDfds(result.data);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Erro ao obter listas:', error);
                setIsLoading(false);
                navigate('/');
            });
    }, [cnpj, token, navigate]);

    useEffect(() => {
        setStartIndex(0);
    }, [rowsPerPage]);

    const endIndex = startIndex + rowsPerPage;
    const selectedDemandas = CatmatDfd.slice(startIndex, endIndex);
    const totalItems = CatmatDfd.length;

    const openDeleteModal = (item) => {
        setItemToDelete(item);
        setShowConfirmDeleteModal(true);
    };

    const handleDeleteConfirm = () => {
        setShowConfirmDeleteModal(false);
        if (itemToDelete) {
            axios.get(`${API_BASE_URL.CATMAT_DFD_ITEM}/exists`, {
                params: { cnpj: itemToDelete.cnpj, dfd: itemToDelete.dfd },
                headers: { Authorization: `Bearer ${token}` }
            }).then(result => {
                if (result.data.exists) {
                    setModalMessage(
                        "Esta Demanda possui vínculos e não pode ser excluída. Exclua os vínculos antes de tentar novamente."
                    );
                    setShowErrorModal(true);
                } else {
                    setIsDeleting(true);
                    axios.delete(`${API_BASE_URL.CATMAT_DFD}/delete`, {
                        params: { cnpj: itemToDelete.cnpj, dfd: itemToDelete.dfd },
                        headers: { Authorization: `Bearer ${token}` }
                    })
                        .then(() => {
                            setCatmatDfds(prevState =>
                                prevState.filter(item => item.dfd !== itemToDelete.dfd)
                            );
                            setShowConfirmDeleteModal(false);
                        })
                        .catch(error => {
                            console.error('Erro ao excluir o item:', error);
                        })
                        .finally(() => setIsDeleting(false));
                }
            }).catch(error => {
                console.error('Erro ao verificar vínculos:', error);
            });
        }
    };

    async function handlePrint(dfd) {
        setIsLoading(true);
        try {
            const response = await axios.post(
                `${API_BASE_URL.CATMAT_DFD}/preencherDfd`,
                dfd,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                    responseType: 'blob',
                }
            );

            const blob = new Blob([response.data], {
                type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `DEMANDA CATMAT ${dfd.dfd}.docx`;
            link.click();
        } catch (error) {
            console.error('Erro ao preencher o template:', error);
            setModalMessage('Erro ao preencher o template.');
            setShowErrorModal(true);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <div className='full-screen-div'>
            <Navbar />
            <div className="container mt-4 pt-5">
                <ResponsiveShadow className="container mt-4 mb-4">
                    <main role="main" className="col-md-12 ml-sm-auto col-lg-12 px-md-4">
                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-1 mb-2 border-bottom">
                            <h3 className="h3">Demandas CATMAT</h3>
                        </div>
                        <div className="d-flex justify-content-start mb-2">
                            <Link className="btn btn-secondary btn-sm me-2" to="/home">
                                <i className="bi bi-house" />
                            </Link>
                            <Link to="/demanda_catmat" className='btn btn-success btn-sm'>
                                <i className="bi bi-plus-lg" /> Novo
                            </Link>
                        </div>
                        <LoadingModal loading={isLoading} />
                        {!isLoading && (
                            <>
                                <div className="table-responsive-sm">
                                    <table className="table table-hover table-striped table-sm">
                                        <thead className="table-secondary">
                                            <tr>
                                                <th scope="col" className="align-middle">Nº</th>
                                                <th scope="col" className="align-middle">Data Criação</th>
                                                <th scope="col" className="align-middle">Área Requisitante</th>
                                                <th scope="col" className="align-middle">Objeto</th>
                                                <th scope="col" className="text-center align-middle" >Ações</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {selectedDemandas.map(c => (
                                                <tr key={`${c.cnpj}-${c.dfd}`}>
                                                    <td>{c.dfd}</td>
                                                    <td>{formatDateView(c.dataCriacao)}</td>
                                                    <td>{c.requisitante}</td>
                                                    <td>{c.objeto}</td>
                                                    <td className="text-center">
                                                        <div className="d-flex flex-wrap justify-content-center gap-2">
                                                            <Link to="/demanda_catmat" state={{ dfd: c }} className="btn btn-primary btn-sm flex-fill">
                                                                <i className="bi bi-pencil-fill me-1" /> Editar
                                                            </Link>
                                                            <Link to="/demanda_catmat_itens" state={{ dfd: c }} className="btn btn-success btn-sm flex-fill">
                                                                <i className="bi bi-boxes me-1" /> Itens
                                                            </Link>
                                                            <button onClick={() => handlePrint(c)} className="btn btn-secondary btn-sm flex-fill">
                                                                <i className="bi bi-printer me-1" /> Imprimir
                                                            </button>
                                                            <button onClick={() => openDeleteModal(c)} className="btn btn-danger btn-sm flex-fill" disabled={isDeleting}>
                                                                <i className="bi bi-trash3 me-1" /> {isDeleting ? 'Excluindo...' : 'Excluir'}
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <Pagination
                                        totalItems={totalItems}
                                        rowsPerPage={rowsPerPage}
                                        startIndex={startIndex}
                                        onRowsPerPageChange={(e) => setRowsPerPage(Number(e.target.value))}
                                        onFirstItems={() => setStartIndex(0)}
                                        onPreviousItems={() => setStartIndex(Math.max(startIndex - rowsPerPage, 0))}
                                        onNextItems={() => setStartIndex(Math.min(startIndex + rowsPerPage, totalItems - rowsPerPage))}
                                        onLastItems={() => setStartIndex(Math.max(totalItems - rowsPerPage, 0))}
                                    />
                                </div>
                            </>
                        )}
                    </main>
                </ResponsiveShadow>
            </div>
            <ConfirmDeleteModal
                show={showConfirmDeleteModal}
                onClose={() => setShowConfirmDeleteModal(false)}
                onConfirm={handleDeleteConfirm}
            />
            <ErrorModal
                show={showErrorModal}
                onClose={() => setShowErrorModal(false)}
                message={modalMessage}
            />
        </div>
    );
}

export default CatmatDemandas;
