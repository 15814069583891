import { useState, useEffect, useRef } from 'react';
import './css/Cliente.css';
import axios from 'axios';
import Navbar from './components/Navbar';
import { API_BASE_URL } from './ApiConfig';
import { Link, useLocation } from 'react-router-dom';
import { unformatCNPJ, formatCNPJ, isValidCNPJ } from './utils/cnpjUtils';
import { unformatTelefone, formatTelefone } from './utils/telefoneUtils';
import { unformatCelular, formatCelular } from './utils/celularUtils';
import { unformatCEP, formatCEP } from './utils/cepUtils';
import CNPJMaskInput from './components/CNPJMaskInput';
import TelefoneMaskInput from './components/TelefoneMaskInput'
import CelularMaskInput from './components/CelularMaskInput'
import CEPMaskInput from './components/CEPMaskInput';
import ResponsiveShadow from './components/ResponsiveShadow';

function Cliente() {
    const [cliente, setCliente] = useState({ cnpj: '', nome: '', email: '', telefone: '', fax: '', logrNome: '', logrNum: '', logrCompl: '', logrBairro: '', logrCep: '', logrCidade: '', logrUf: '' });
    const [UFs, setUFs] = useState([]);
    const [cidades, setCidades] = useState([]); // Estado para armazenar as cidades
    const token = localStorage.getItem('token');
    const location = useLocation();
    const [cnpjError, setCnpjError] = useState('');
    const refs = useRef({});

    useEffect(() => {
        if (location.state && location.state.cliente) {
            setCliente(location.state.cliente);
        }
        axios.get(`${API_BASE_URL.UF}/all`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(result => {
            setUFs(result.data);
        }).catch(error => {
            console.error('Erro ao obter listas:', error);
        });
    }, [location.state]);

    // Efeito para carregar cidades quando a UF for alterada
    useEffect(() => {
        if (cliente.logrUf) {
            axios.get(`${API_BASE_URL.MUNICIPIO}/uf/${cliente.logrUf}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(result => {
                setCidades(result.data);
            }).catch(error => {
                console.error('Erro ao carregar cidades:', error);
            });
        } else {
            setCidades([]);
        }
    }, [cliente.logrUf]);

    function handleChange(event) {
        const { name, value, selectionStart } = event.target;
        let formattedValue = value;

        // Lógica de formatação específica para o CNPJ
        if (name === 'cnpj') {
            const unformattedValue = unformatCNPJ(value);
            formattedValue = formatCNPJ(unformattedValue);

            // Atualiza o estado e posiciona o cursor para o CNPJ
            setCliente(prevState => ({
                ...prevState,
                [name]: formattedValue
            }));

            if (refs.current[name]) {
                const newPos = selectionStart + (formattedValue.length - unformattedValue.length);
                refs.current[name].setSelectionRange(newPos, newPos);
            }
        } else {
            // Para outros campos, mantém a formatação normal
            formattedValue = name === 'email' ? value.toLowerCase() : value.toUpperCase();
            setCliente(prevState => ({
                ...prevState,
                [name]: formattedValue
            }));
        }
    }


    function handleSubmit(event) {
        event.preventDefault();

        // Remover pontuação do CNPJ antes de enviar para a API
        const unformattedCliente = {
            ...cliente,
            cnpj: unformatCNPJ(cliente.cnpj),
        };

        if (!isValidCNPJ(unformattedCliente.cnpj)) {
            setCnpjError('CNPJ inválido');
            return;
        }
        setCnpjError('');

        if (unformattedCliente.cnpj.length === 14) {
            axios.post(`${API_BASE_URL.CLIENTE}/save`, unformattedCliente, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(result => {
                window.location.href = '/clientes';
            }).catch(error => {
                console.error('Erro ao salvar cliente:', error);
            });
        } else {
            console.error('CNPJ inválido');
        }
    }


    return (
        <div className='full-screen-div'>
            <Navbar />
            <div className="container mt-4 pt-5">
                <ResponsiveShadow className="container mt-4 mb-4">
                    <main role="main" className="col-md-12 ml-sm-auto col-lg-12 px-md-4">
                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-2 border-bottom">
                            <h3 className="h3">Cadastro de Cliente</h3>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className='row g-3'>
                                <div className='col-12 col-sm-3 col-md-3 input-group-sm'>
                                    <label className='form-label-sm'>CNPJ</label>
                                    <CNPJMaskInput
                                        value={formatCNPJ(cliente.cnpj)}
                                        onChange={(e) =>
                                            handleChange({ target: { name: 'cnpj', value: unformatCNPJ(e.target.value) } })}
                                        name='cnpj'
                                        ref={el => refs.current.cnpj = el}
                                        type='text'
                                    />
                                    {cnpjError && <small className="text-danger">{cnpjError}</small>}
                                </div>
                                <div className='col-12 col-sm-9 col-md-9 input-group-sm'>
                                    <label className='form-label-sm'>Nome</label>
                                    <input ref={el => refs.current.nome = el} onChange={handleChange} value={cliente.nome} name='nome' type='text' className='form-control'></input>
                                </div>
                                <div className='col-md-6 input-group-sm'>
                                    <label className='form-label-sm'>Email</label>
                                    <input ref={el => refs.current.email = el} onChange={handleChange} value={cliente.email} name='email' type='text' className='form-control'></input>
                                </div>
                                <div className='col-md-3 input-group-sm'>
                                    <label className='form-label-sm'>Telefone</label>
                                    <TelefoneMaskInput
                                        value={formatTelefone(cliente.telefone)}
                                        onChange={(e) =>
                                            handleChange({ target: { name: 'telefone', value: unformatTelefone(e.target.value) } })}
                                        name='telefone'
                                        ref={el => refs.current.telefone = el}
                                        type='text'
                                    />
                                </div>
                                <div className='col-md-3 input-group-sm'>
                                    <label className='form-label-sm'>Celular</label>
                                    <CelularMaskInput
                                        value={formatCelular(cliente.fax)}
                                        onChange={(e) =>
                                            handleChange({ target: { name: 'fax', value: unformatCelular(e.target.value) } })}
                                        name='celular'
                                        ref={el => refs.current.fax = el}
                                        type='text'
                                    />
                                </div>
                                <div className='col-md-6 input-group-sm'>
                                    <label className='form-label-sm'>Endereço</label>
                                    <input ref={el => refs.current.logrNome = el} onChange={handleChange} value={cliente.logrNome} name='logrNome' type='text' className='form-control'></input>
                                </div>
                                <div className='col-md-2 input-group-sm'>
                                    <label className='form-label-sm'>Número</label>
                                    <input ref={el => refs.current.logrNum = el} onChange={handleChange} value={cliente.logrNum} name='logrNum' type='text' className='form-control'></input>
                                </div>
                                <div className='col-md-4 input-group-sm'>
                                    <label className='form-label-sm'>Complemento</label>
                                    <input ref={el => refs.current.logrCompl = el} onChange={handleChange} value={cliente.logrCompl} name='logrCompl' type='text' className='form-control'></input>
                                </div>
                                <div className='col-md-4 input-group-sm'>
                                    <label className='form-label-sm'>Bairro</label>
                                    <input ref={el => refs.current.logrBairro = el} onChange={handleChange} value={cliente.logrBairro} name='logrBairro' type='text' className='form-control'></input>
                                </div>
                                <div className='col-md-3 input-group-sm'>
                                    <label className='form-label-sm'>CEP</label>
                                    <CEPMaskInput
                                        value={formatCEP(cliente.logrCep)}
                                        onChange={(e) =>
                                            handleChange({ target: { name: 'logrCep', value: unformatCEP(e.target.value) } })}
                                        name='logrCep'
                                        ref={el => refs.current.cep = el}
                                        type='text'
                                    />
                                </div>
                                <div className='col-md-2 input-group-sm'>
                                    <label className='form-label-sm'>UF</label>
                                    <select className='form-select' value={cliente.logrUf} onChange={handleChange} name='logrUf'>
                                        <option value="">Selecione uma UF...</option>
                                        {UFs.map((uf, index) => (
                                            <option key={index} value={uf.sigla}>
                                                {uf.sigla}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className='col-md-3 input-group-sm'>
                                    <label className='form-label-sm'>Cidade</label>
                                    <select className='form-select' value={cliente.logrCidade} onChange={handleChange} name='logrCidade'>
                                        <option value="">Selecione uma cidade...</option>
                                        {cidades.map((cidade, index) => (
                                            <option key={index} value={cidade.nome}>
                                                {cidade.nome}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="form-group mb-3">
                                <button type="submit" className="btn btn-primary mt-3 mb-3">Salvar</button>
                                <Link className="btn btn-secondary mt-3 mb-3 mx-2" to="/clientes">Cancelar</Link>
                            </div>
                        </form>
                    </main>
                </ResponsiveShadow>
            </div>
        </div>
    );
}

export default Cliente;
