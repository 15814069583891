// Função para remover pontuação do Celular
export function unformatCelular(celular) {
    return celular.replace(/[^\d]/g, ''); // Remove todos os caracteres que não são dígitos
}

// Função para formatar Celular (00000000000 -> (00) 00000-0000)
export function formatCelular(celular) {
    if (celular.length === 11) {
        return celular.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
    }
    return celular;
}
