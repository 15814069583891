import { useEffect, useState } from 'react';
import axios from 'axios';
import Navbar from './components/Navbar';
import { API_BASE_URL } from './ApiConfig';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Pagination from './components/Pagination';
import ResponsiveShadow from './components/ResponsiveShadow';

function UsuariosVinculos() {
    const [Usuario, setUsuario] = useState({ cpf: '', nome: '', login: '', senha: '', situacao: '', role: '' });
    const [UsuariosBind, setUsuariosBind] = useState([]);
    const [atualizar, setAtualizar] = useState(false);
    const [UsuarioBind, setUsuarioBind] = useState({ cnpj: '', cpf: '', tipo: '', situacao: '' });
    const navigate = useNavigate();
    const location = useLocation();
    const [Clientes, setClientes] = useState([]);
    const token = localStorage.getItem('token');
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [startIndex, setStartIndex] = useState(0);

    // Obter lista de clientes
    useEffect(() => {
        axios.get(`${API_BASE_URL.CLIENTE}/all`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(result => {
            setClientes(result.data);
        }).catch(error => {
            console.error('Erro ao obter listas:', error);
        });
    }, [token]);

    // Se houver um usuário na rota, carregar o estado
    useEffect(() => {
        if (location.state && location.state.usuario) {
            setUsuario(location.state.usuario);
        }
    }, [location.state]);

    // Sincronizar o CPF do Usuario com o UsuarioBind
    useEffect(() => {
        if (Usuario.cpf) {
            setUsuarioBind(prevState => ({
                ...prevState,
                cpf: Usuario.cpf,
            }));
        }
    }, [Usuario.cpf]);

    // Obter os vínculos do usuário
    useEffect(() => {
        if (Usuario.cpf !== '') {
            axios.get(`${API_BASE_URL.USUARIO_BIND}/cpf`, {
                params: { cpf: Usuario.cpf },
                headers: { Authorization: `Bearer ${token}` }
            }).then(result => {
                setUsuariosBind(result.data);
            }).catch(error => {
                console.error('Erro ao obter clientes:', error);
            });
        }
    }, [Usuario.cpf, token, atualizar]); // 'atualizar' como dependência para refazer a busca

    // Função para lidar com as alterações dos selects e inputs
    function handleChange(event) {
        const { name, value } = event.target;
        setUsuarioBind(prevState => ({
            ...prevState,
            [name]: value.toUpperCase(),
        }));
    }

    // Função para salvar ou atualizar o UsuarioBind
    function handleSubmit(event) {
        event.preventDefault();
        console.log('DADOS DO SAVE: ', UsuarioBind);
        axios.post(`${API_BASE_URL.USUARIO_BIND}/save`, UsuarioBind, {
            headers: { Authorization: `Bearer ${token}` }
        }).then(result => {
            setAtualizar(!atualizar); // Atualiza a tabela ao salvar
            setUsuarioBind({ cnpj: '', cpf: Usuario.cpf, tipo: '', situacao: '' }); // Limpa os campos mantendo o CPF
        }).catch(error => {
            console.error('Erro ao salvar:', error);
        });
    }

    // Função para deletar o vínculo
    function handleDelete(usuarioBind) {
        axios.delete(`${API_BASE_URL.USUARIO_BIND}/delete/${usuarioBind.cpf}/${usuarioBind.cnpj}`, {
            headers: { Authorization: `Bearer ${token}` }
        }).then(result => {
            setAtualizar(!atualizar); // Atualiza a tabela após a exclusão
        }).catch(error => {
            console.error('Erro ao deletar:', error);
        });
    }

    // Paginação
    const endIndex = startIndex + rowsPerPage;
    const selectedUsuarios = UsuariosBind.slice(startIndex, endIndex);
    const totalItems = UsuariosBind.length;
    useEffect(() => {
        setStartIndex(0);
    }, [rowsPerPage]);

    return (
        <div className='full-screen-div'>
            <Navbar />
            <div className="container mt-4 pt-5">
                <ResponsiveShadow className="container mt-4 mb-4">
                    <main role="main" className="col-md-12 ml-sm-auto col-lg-12 px-md-4 mb-3">
                        <h3 className="h3">Vinculação de Usuários</h3>
                        <form onSubmit={handleSubmit}>
                            <div className='row g-3'>
                                <div className='col-md-3 input-group-sm'>
                                    <label className='form-label'>Cliente/Entidade</label>
                                    <select className='form-select' value={UsuarioBind.cnpj} onChange={handleChange} name='cnpj'>
                                        <option value="">Selecione...</option>
                                        {Clientes.map((option, index) => (
                                            <option key={index} value={option.cnpj}>
                                                {option.nome}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className='col-md-3 input-group-sm'>
                                    <label className='form-label'>Tipo de Vínculo</label>
                                    <select onChange={handleChange} value={UsuarioBind.tipo} name='tipo' className='form-select'>
                                        <option value="">Selecione...</option>
                                        <option value="ADMIN">ADMINISTRADOR</option>
                                        <option value="USER">USUÁRIO</option>
                                    </select>
                                </div>
                                <div className='col-md-12 mt-2 mb-2 input-group-sm'>
                                    <Link to="/usuarios" className='btn btn-secondary mx-1'>
                                        <i className="bi bi-arrow-left" /> Voltar
                                    </Link>
                                    <button type="submit" className='btn btn-success mx-1'>
                                        <i className="bi bi-check-lg" /> Salvar
                                    </button>
                                </div>
                            </div>
                        </form><div className="table-responsive-sm">
                            <table className="table table-hover table-striped table-sm">
                                <thead className="table-secondary">
                                    <tr>
                                        <th scope="col">Nome</th>
                                        <th scope="col">Tipo de Vínculo</th>
                                        <th scope="col">Ações</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedUsuarios.map(usu => (
                                        <tr key={`${usu.cnpj}-${usu.cpf}`}>
                                            <td>{usu.appCliente.nome}</td>
                                            <td>{usu.tipo}</td>
                                            <td>
                                                <button onClick={() => handleDelete(usu)} className='btn btn-danger btn-sm mx-1 my-1'>
                                                    <i className="bi bi-trash-fill" /> Excluir
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        {/* Paginação */}
                        <Pagination
                            totalItems={totalItems}
                            rowsPerPage={rowsPerPage}
                            startIndex={startIndex}
                            onRowsPerPageChange={(e) => setRowsPerPage(Number(e.target.value))}
                            onFirstItems={() => setStartIndex(0)}
                            onPreviousItems={() => setStartIndex(Math.max(startIndex - rowsPerPage, 0))}
                            onNextItems={() => setStartIndex(Math.min(startIndex + rowsPerPage, totalItems - rowsPerPage))}
                            onLastItems={() => setStartIndex(Math.max(totalItems - rowsPerPage, 0))}
                        />
                    </main>
                </ResponsiveShadow>
            </div>
        </div>
    );
}

export default UsuariosVinculos;
