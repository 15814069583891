import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './css/Cliente.css';
import axios from 'axios';
import Navbar from './components/Navbar';
import { API_BASE_URL } from './ApiConfig';
import ConfirmDeleteModal from './components/ConfirmDeleteModal'; // Import do modal de confirmação
import ErrorModal from './components/ErrorModal';
import SuccessModal from './components/SuccessModal';
import Pagination from './components/Pagination';
import ResponsiveShadow from './components/ResponsiveShadow';

function Requisitante() {
    const [Requisitante, setRequisitante] = useState({ cnpj: '', num: '', nome: '' });
    const [Requisitantes, setRequisitantes] = useState([]);
    const [selectedRequisitante, setSelectedRequisitante] = useState(null);
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [message, setMessage] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [startIndex, setStartIndex] = useState(0);
    const [isAscending, setIsAscending] = useState(true); // Estado para controlar a direção da ordenação
    const totalItems = Requisitantes.length;
    const token = localStorage.getItem('token');
    const location = useLocation();
    const descricaoRef = useRef(null);

    useEffect(() => {
        const storedCnpj = localStorage.getItem('cnpj');
        if (location.state && location.state.requisitante) {
            setRequisitante({
                ...location.state.requisitante,
                cnpj: storedCnpj || location.state.requisitante.cnpj,
            });
        } else {
            setRequisitante((prev) => ({
                ...prev,
                cnpj: storedCnpj || '',
            }));
        }
    }, [location.state]);

    const fetchRequisitantes = () => {
        if (Requisitante.cnpj) {
            axios.get(`${API_BASE_URL.REQUISITANTE}/all_cnpj`, {
                params: { cnpj: Requisitante.cnpj },
                headers: { Authorization: `Bearer ${token}` }
            }).then(response => {
                // Ordena os dados recebidos antes de atualizar o estado
                const sortedData = [...response.data].sort((a, b) => a.nome.localeCompare(b.nome));
                setRequisitantes(sortedData);
                setIsAscending(true); // Definindo para ordenar de forma ascendente ao carregar
            }).catch(error => {
                console.error("Erro ao buscar requisitantes:", error);
            });
        }
    };

    useEffect(() => {
        fetchRequisitantes();
    }, [Requisitante.cnpj]);

    const selectedRequisitantes = Requisitantes.slice(startIndex, startIndex + rowsPerPage);
    useEffect(() => {
        setStartIndex(0);
    }, [rowsPerPage]);

    function handleChange(event) {
        setRequisitante({ ...Requisitante, [event.target.name]: event.target.value.toUpperCase() });
    }

    function handleNomeInput(event) {
        const cursorPosition = event.target.selectionStart;
        const newValue = event.target.value.toUpperCase();

        setRequisitante(prevState => ({
            ...prevState,
            nome: newValue
        }));

        setTimeout(() => {
            descricaoRef.current.selectionStart = cursorPosition;
            descricaoRef.current.selectionEnd = cursorPosition;
        }, 0);
    }

    function handleKeyDown(event) {
        if (event.key === 'Enter') {
            event.preventDefault(); // Impede o envio do formulário ao pressionar "Enter"
        }
    }

    function handleSubmit(event) {
        event.preventDefault();

        if (Requisitante.cnpj && Requisitante.nome) {
            axios.post(`${API_BASE_URL.REQUISITANTE}/save`, Requisitante, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(() => {
                setMessage("Área Requisitante salva com sucesso!");
                fetchRequisitantes();
                setRequisitante({ cnpj: Requisitante.cnpj, num: '', nome: '' });
                setShowSuccessModal(true);
            }).catch(error => {
                console.error("Erro ao salvar requisitante:", error);
                setMessage("Erro ao salvar requisitante. Tente novamente mais tarde.");
                setShowErrorModal(true);
            });
        } else {
            setMessage("Por favor, preencha todos os campos obrigatórios antes de salvar.");
            setShowErrorModal(true);
        }
    }

    function handleDeleteClick(req) {
        setSelectedRequisitante(req);
        setShowConfirmDeleteModal(true);
    }

    function handleConfirmDelete() {
        axios.delete(`${API_BASE_URL.REQUISITANTE}/delete`, {
            params: { cnpj: selectedRequisitante.cnpj, num: selectedRequisitante.num },
            headers: { Authorization: `Bearer ${token}` }
        }).then(() => {
            fetchRequisitantes();
            setShowConfirmDeleteModal(false);
            setSelectedRequisitante(null);
        }).catch(error => {
            console.error("Erro ao excluir requisitante:", error);
        });
    }

    // Função para ordenar requisitantes por nome
    const sortByName = () => {
        // Invertemos o valor de isAscending a cada clique
        setIsAscending(prevIsAscending => {
            const newIsAscending = !prevIsAscending;

            const sortedData = [...Requisitantes].sort((a, b) => {
                return newIsAscending ? a.nome.localeCompare(b.nome) : b.nome.localeCompare(a.nome);
            });

            setRequisitantes(sortedData); // Atualiza a lista ordenada
            return newIsAscending; // Atualiza o estado de isAscending
        });
    };


    return (
        <div className='full-screen-div'>
            <Navbar />
            <div className="container mt-4 pt-5">
                <ResponsiveShadow className="container mt-4 mb-4">
                    <main role="main" className="col-md-12 ml-sm-auto col-lg-12 px-md-4">
                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                            <h3 className="h3">Cadastro de Requisitantes</h3>
                        </div>
                        <div className="col-md-6 ml-sm-auto">
                            <form onSubmit={handleSubmit} onKeyDown={handleKeyDown}>
                                <div className='row g-3'>
                                    <div className={`col-md-${Requisitante.num ? '5' : '6'} input-group-sm`}>
                                        <label className='form-label'>Nome da Área Requisitante</label>
                                        <input ref={descricaoRef} onInput={handleNomeInput} value={Requisitante.nome} name='nome' type='text' className='form-control' />
                                    </div>
                                    <div className='col-md-12 mb-3 input-group-sm'>
                                        <Link to="/home" className='btn btn-secondary mx-1'><i className="bi bi-house" /></Link>
                                        <button type='submit' className='btn btn-success mx-1'><i className="bi bi-check-lg" /> Salvar</button>
                                    </div>
                                </div>
                            </form>
                        </div>

                        {/* Tabela para exibir os registros */}
                        <div className="table-responsive-sm">
                            <table className="table table-striped table-bordered table-hover">
                                <thead className="table-secondary">
                                    <tr>
                                        <th onClick={sortByName} style={{ cursor: 'pointer' }}>Nome {isAscending ? '▲' : '▼'}</th>
                                        <th>Ações</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedRequisitantes.map((req, index) => (
                                        <tr key={index}>
                                            <td>{req.nome}</td>
                                            <td>
                                                <button
                                                    onClick={() => handleDeleteClick(req)}
                                                    className="btn btn-danger btn-sm"
                                                >
                                                    Excluir
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        {/* Componente de Paginação */}
                        <Pagination
                            totalItems={totalItems}
                            rowsPerPage={rowsPerPage}
                            startIndex={startIndex}
                            onRowsPerPageChange={(e) => setRowsPerPage(Number(e.target.value))}
                            onFirstItems={() => setStartIndex(0)}
                            onPreviousItems={() => setStartIndex(Math.max(startIndex - rowsPerPage, 0))}
                            onNextItems={() => setStartIndex(Math.min(startIndex + rowsPerPage, totalItems - rowsPerPage))}
                            onLastItems={() => setStartIndex(Math.max(totalItems - rowsPerPage, 0))}
                        />
                    </main>
                </ResponsiveShadow>
            </div>
            {/* Modal de confirmação de exclusão */}
            <ConfirmDeleteModal
                show={showConfirmDeleteModal}
                onClose={() => setShowConfirmDeleteModal(false)} // Fechar modal ao cancelar
                onConfirm={handleConfirmDelete} // Executa exclusão e fecha modal
            />
            <ErrorModal
                show={showErrorModal}
                message={message}
                onClose={() => setShowErrorModal(false)}
            />

            <SuccessModal
                show={showSuccessModal}
                message={message}
                onClose={() => setShowSuccessModal(false)}
            />
        </div>
    );
}

export default Requisitante;
