import React, { useEffect, useState, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './css/Cliente.css';
import axios from 'axios';
import Navbar from './components/Navbar';
import { API_BASE_URL } from './ApiConfig';
import 'bootstrap-icons/font/bootstrap-icons.css';
import LoadingModal from './components/LoadingModal';
import ErrorModal from './components/ErrorModal';
import Pagination from './components/Pagination';
import ResponsiveShadow from './components/ResponsiveShadow';

function CatserDemandaItens() {
    const [Demanda, setDemanda] = useState({ cnpj: '', dfd: '', dataConclusao: '', requisitante: '', objeto: '', prioridade: '', justificativaPrioridade: '', justificativaDemanda: '', tipo: '', usuario: '' });
    const [DemandaItens, setDemandaItens] = useState([]);
    const [unidades, setUnidades] = useState([]);
    const [itemToDelete, setItemToDelete] = useState(null);
    const [shouldFetch, setShouldFetch] = useState(false);
    const token = localStorage.getItem('token');
    const navigate = useNavigate();
    const location = useLocation();
    const modalRef = useRef(null);
    const saveModalRef = useRef(null);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [startIndex, setStartIndex] = useState(0);
    const [modalMessage, setModalMessage] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        if (location.state && location.state.dfd) {
            setDemanda(location.state.dfd);
        }
        if (Demanda.cnpj !== '') {
            axios.get(`${API_BASE_URL.CATSER_DFD_ITEM}/dfd`, {
                params: {
                    cnpj: Demanda.cnpj,
                    dfd: Demanda.dfd
                },
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(result => {
                const formattedData = result.data.map(item => ({
                    ...item,
                    vlrUnit: formatCurrency(item.vlrUnit),
                }));
                setDemandaItens(formattedData);
                setIsLoading(false);
            }).catch(error => {
                console.error('Erro ao obter listas:', error);
                setIsLoading(false);
            });

            axios.get(`${API_BASE_URL.UNIDADE_CATSER}/all`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(result => {
                setUnidades(result.data);
            }).catch(error => {
                console.error('Erro ao obter unidades:', error);
            });
        }

    }, [location.state, Demanda, shouldFetch]);
    if (!DemandaItens) {
        return <div>Loading...</div>;
    }
    const endIndex = startIndex + rowsPerPage;
    const selectedDemandaItens = DemandaItens.slice(startIndex, endIndex);
    const totalItems = DemandaItens.length;

    function handleRowsPerPageChange(event) {
        setRowsPerPage(Number(event.target.value));
        setStartIndex(0); // Reinicia para o início quando muda o número de itens por página
    }

    function handleNextItems() {
        const newStartIndex = Math.min(startIndex + rowsPerPage, totalItems - rowsPerPage);
        setStartIndex(newStartIndex);
    }

    function handlePreviousItems() {
        const newStartIndex = Math.max(startIndex - rowsPerPage, 0);
        setStartIndex(newStartIndex);
    }

    function handleFirstItems() {
        setStartIndex(0);
    }

    function handleLastItems() {
        const newStartIndex = Math.max(totalItems - rowsPerPage, 0);
        setStartIndex(newStartIndex);
    }
    function handleDelete(dfd) {
        setItemToDelete(dfd);
        const modal = new window.bootstrap.Modal(modalRef.current);
        modal.show();
    }

    function handleDeleteConfirm() {
        axios.delete(`${API_BASE_URL.CATSER_DFD_ITEM}/delete`, {
            params: {
                cnpj: itemToDelete.cnpj,
                dfd: itemToDelete.dfd,
                grupo: itemToDelete.grupo,
                classe: itemToDelete.classe,
                item: itemToDelete.item
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(result => {
            setShouldFetch(!shouldFetch);
            setItemToDelete(null);
            const modal = window.bootstrap.Modal.getInstance(modalRef.current);
            modal.hide();
        })
            .catch(error => {
                console.error('Erro ao excluir o item:', error);
            });
    }

    function formatDateForInput(dateString) {
        if (dateString) {
            const date = new Date(dateString);
            const formattedDate = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;
            return formattedDate;
        }
        return '';
    }

    function formatCurrency(value) {
        if (isNaN(value)) return value;
        return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
    }

    function unformatCurrency(value) {
        return value.replace(/\D/g, '') / 100;
    }

    function calculateTotal(quantidade, vlrUnit) {
        const quantidadeNum = parseFloat(quantidade) || 0;
        const vlrUnitNum = unformatCurrency(vlrUnit) || 0;
        return formatCurrency(quantidadeNum * vlrUnitNum);
    }

    const handleInputChange = (index, field, value) => {
        const updatedDemandaItens = [...DemandaItens];
        if (field === 'vlrUnit') {
            updatedDemandaItens[index][field] = formatCurrency(unformatCurrency(value));
        } else {
            updatedDemandaItens[index][field] = value;
        }
        setDemandaItens(updatedDemandaItens);
    };

    const handleSave = (index) => {
        const demandasItens = DemandaItens[index];
        const itemToSave = {
            ...demandasItens,
            vlrUnit: unformatCurrency(demandasItens.vlrUnit)
        };
        axios.post(`${API_BASE_URL.DFD_ITEM}/save`, itemToSave, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(result => {
            console.log('Item atualizado com sucesso:', result.data);
            const modal = new window.bootstrap.Modal(saveModalRef.current);
            modal.show();
        }).catch(error => {
            console.error('Erro ao atualizar o item:', error);
        });
    };

    const handleSaveAll = () => {
        const itemsToSave = DemandaItens.map(item => ({
            ...item,
            vlrUnit: unformatCurrency(item.vlrUnit),
        }));
        console.log("ITENS: ", itemsToSave);
        axios.post(`${API_BASE_URL.CATSER_DFD_ITEM}/saveAll`, itemsToSave, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(result => {
            console.log('Itens atualizados com sucesso:', result.data);
            const modal = new window.bootstrap.Modal(saveModalRef.current);
            modal.show();
        }).catch(error => {
            console.error('Erro ao atualizar os itens:', error);
        });
    };

    return (
        <div className='full-screen-div'>
            <Navbar />
            <div className="container mt-4 pt-5">
                <ResponsiveShadow className="container mt-4 mb-4">
                    <main role="main" className="col-md-12 ml-sm-auto col-lg-12 px-md-4">
                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-1 mb-2 border-bottom">
                            <h3 className="h3">Itens da Demanda CATSER</h3>
                        </div>

                        {/* Agrupamento dos botões */}
                        <div className="d-flex justify-content-start mb-2">
                            <Link to="/demandas_catser" state={{ dfd: Demanda }} className='btn btn-secondary btn-sm me-2'>
                                <i className="bi bi-arrow-left" /> Voltar
                            </Link>
                            <Link to="/demanda_catser_item_find" state={{ dfd: Demanda }} className='btn btn-success btn-sm me-2'>
                                <i className="bi bi-plus-lg" /> Adicionar Item
                            </Link>
                            <button onClick={handleSaveAll} className='btn btn-primary btn-sm'>
                                <i className="bi bi-save" /> Salvar Todos
                            </button>
                        </div>
                        <LoadingModal loading={isLoading} />
                        {!isLoading && (
                            <>
                                <div className="table-responsive">
                                    <table className="table table-hover table-sm">
                                        <thead className="table-secondary">
                                            <tr>
                                                <th scope="col" className="text-center align-middle">Grupo</th>
                                                <th scope="col" className="text-center align-middle">Classe</th>
                                                <th scope="col" className="text-center align-middle">Código</th>
                                                <th scope="col" className="text-center align-middle">Descrição</th>
                                                <th scope="col" className="text-center align-middle">Unidade</th>
                                                <th scope="col" className="text-center align-middle">Quantidade</th>
                                                <th scope="col" className="text-center align-middle">Valor Unitário</th>
                                                <th scope="col" className="text-center align-middle">Valor Total</th>
                                                <th scope="col" className="text-center align-middle">Ações</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {selectedDemandaItens.map((c, index) => (
                                                <tr key={`${c.cnpj}-${c.dfd}-${c.grupo}-${c.classe}-${c.item}`}>
                                                    <td>{c.grupo}</td>
                                                    <td>{c.classe}</td>
                                                    <td>{c.item}</td>
                                                    <td>{c.catserItem.descricao}</td>
                                                    <td>
                                                        <select
                                                            name="unidade"
                                                            value={c.unidade}
                                                            onChange={(e) => handleInputChange(index, 'unidade', e.target.value)}
                                                            className="form-select form-select-sm"
                                                        >   <option value="">Selecione...</option>
                                                            {unidades.map(unidade => (
                                                                <option key={unidade.id} value={unidade.descricao}>{unidade.descricao}</option>
                                                            ))}
                                                        </select>
                                                    </td>
                                                    <td className="text-end">
                                                        <input
                                                            type="number"
                                                            name="quantidade"
                                                            value={c.quantidade}
                                                            onChange={(e) => handleInputChange(index, 'quantidade', e.target.value)}
                                                            className="form-control form-control-sm text-end"
                                                        />
                                                    </td>
                                                    <td className="text-end">
                                                        <input
                                                            type="text"
                                                            name="vlrUnit"
                                                            value={c.vlrUnit}
                                                            onChange={(e) => handleInputChange(index, 'vlrUnit', e.target.value)}
                                                            className="form-control form-control-sm text-end"
                                                        />
                                                    </td>
                                                    <td className="text-end">
                                                        {calculateTotal(c.quantidade, c.vlrUnit)}
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="d-flex justify-content-around">
                                                            <button onClick={() => handleDelete(c)} className="btn btn-danger btn-sm" title="Excluir">
                                                                <i className="bi bi-trash"></i>Excluir
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                    <Pagination
                                        totalItems={totalItems}
                                        rowsPerPage={rowsPerPage}
                                        startIndex={startIndex}
                                        onRowsPerPageChange={handleRowsPerPageChange}
                                        onFirstItems={handleFirstItems}
                                        onPreviousItems={handlePreviousItems}
                                        onNextItems={handleNextItems}
                                        onLastItems={handleLastItems}
                                    />
                                </div>
                            </>
                        )}
                    </main>
                </ResponsiveShadow>
            </div>

            {/* Modal de confirmação de exclusão */}
            <div className="modal fade" id="deleteModal" tabIndex="-1" ref={modalRef}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Confirmação de Exclusão</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            Tem certeza de que deseja excluir este item?
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                            <button type="button" className="btn btn-danger" onClick={handleDeleteConfirm}>Excluir</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal de confirmação de salvamento */}
            <div className="modal fade" id="saveModal" tabIndex="-1" ref={saveModalRef}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Sucesso</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            Item salvo com sucesso.
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal">OK</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CatserDemandaItens;
