// cpfUtils.js

// Função para remover pontuação do CPF
export function unformatCPF(cpf) {
    return cpf.replace(/[^\d]/g, ''); // Remove todos os caracteres que não são dígitos
}

// Função para formatar CPF (12345678901 -> 123.456.789-01)
export function formatCPF(cpf) {
    if (cpf.length === 11) {
        return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    }
    return cpf;
}

// Função para validar o CPF
export function isValidCPF(cpf) {
    cpf = unformatCPF(cpf); // Remove a formatação para garantir que tenha apenas números

    if (cpf.length !== 11 || /^(\d)\1{10}$/.test(cpf)) {
        // Verifica se o CPF tem 11 dígitos e se todos os números não são iguais (ex: 111.111.111-11)
        return false;
    }

    // Validação do primeiro dígito verificador
    let soma = 0;
    for (let i = 0; i < 9; i++) {
        soma += parseInt(cpf.charAt(i)) * (10 - i);
    }
    let resto = 11 - (soma % 11);
    let digito1 = resto === 10 || resto === 11 ? 0 : resto;

    // Validação do segundo dígito verificador
    soma = 0;
    for (let i = 0; i < 10; i++) {
        soma += parseInt(cpf.charAt(i)) * (11 - i);
    }
    resto = 11 - (soma % 11);
    let digito2 = resto === 10 || resto === 11 ? 0 : resto;

    // Verifica se os dígitos calculados são iguais aos dígitos verificadores no CPF
    return digito1 === parseInt(cpf.charAt(9)) && digito2 === parseInt(cpf.charAt(10));
}
