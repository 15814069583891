import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './service/Home';
import Clientes from './service/Clientes';
import Usuarios from './service/Usuarios';
import Item from './service/Item';
import LoginPage from './service/LoginPage';
import Listas from './service/Listas';
import ListaCadastro from './service/Lista';
import ListaItens from './service/ListaItens';
import Cliente from './service/Cliente';
import Usuario from './service/Usuario';
import SelecionaEntidade from './service/SelecionaEntidade';
import ItemFind from './service/ItemFind';
import Demandas from './service/Demandas';
import Demanda from './service/Demanda';
import Requisitantes from './service/Requisitantes';
import DemandaItens from './service/DemandaItens';
import DemandaItemFind from './service/DemandaItemFind';
import CatmatDemandas from './service/CatmatDemandas';
import CatmatDemanda from './service/CatmatDemanda';
import CatmatDemandaItens from './service/CatmatDemandaItens';
import CatmatDemandaItemFind from './service/CatmatDemandaItemFind';
import UsuariosVinculos from './service/UsuariosVinculos';
import UploadTemplate from './service/UploadTemplate';
import UploadTemplateDefault from './service/UploadTemplateDefault';
import PreencherTemplate from './service/PreencherTemplate';
import CatserDemandaItemFind from './service/CatserDemandaItemFind';
import CatserDemandaItens from './service/CatserDemandaItens';
import CatserDemanda from './service/CatserDemanda';
import CatserDemandas from './service/CatserDemandas';
import UploadImagem from './service/UploadImagem';
import Relatorios from './service/Relatorios';
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/home" element={<Home />} />
        <Route path="/clientes" element={<Clientes />} />
        <Route path="/cliente" element={<Cliente />} />
        <Route path="/requisitantes" element={<Requisitantes />} />
        <Route path="/item" element={<Item />} />
        <Route path="/item_find" element={<ItemFind />} />
        <Route path="/usuarios" element={<Usuarios />} />
        <Route path="/usuario" element={<Usuario />} />
        <Route path="/listas" element={<Listas />} />
        <Route path="/lista" element={<ListaCadastro />} />
        <Route path="/lista_itens" element={<ListaItens />} />
        <Route path="/demandas_efisco_pe" element={<Demandas />} />
        <Route path="/demanda_efisco_pe" element={<Demanda />} />
        <Route path="/demanda_item_find_efisco_pe" element={<DemandaItemFind />} />
        <Route path="/demanda_itens_efisco_pe" element={<DemandaItens />} />
        <Route path="/demanda_catmat_item_find" element={<CatmatDemandaItemFind />} />
        <Route path="/demanda_catmat_itens" element={<CatmatDemandaItens />} />
        <Route path="/demanda_catmat" element={<CatmatDemanda />} />
        <Route path="/demandas_catmat" element={<CatmatDemandas />} />
        <Route path="/demanda_catser_item_find" element={<CatserDemandaItemFind />} />
        <Route path="/demanda_catser_itens" element={<CatserDemandaItens />} />
        <Route path="/demanda_catser" element={<CatserDemanda />} />
        <Route path="/demandas_catser" element={<CatserDemandas />} />
        <Route path="/seleciona_entidade" element={<SelecionaEntidade />} />
        <Route path="/upload_template" element={<UploadTemplate />} />
        <Route path="/upload_template_default" element={<UploadTemplateDefault />} />
        <Route path="/upload_imagem" element={<UploadImagem />} />
        <Route path="/preencher_template" element={<PreencherTemplate />} />
        <Route path="/usuario_vinculos" element={<UsuariosVinculos />} />
        <Route path="/relatorios" element={<Relatorios />} />
      </Routes>
    </Router>
  );
}

export default App;
