import React, { useEffect, useState, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './css/Cliente.css';
import axios from 'axios';
import Navbar from './components/Navbar';
import { API_BASE_URL } from './ApiConfig';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Pagination from './components/Pagination';
import ResponsiveShadow from './components/ResponsiveShadow';

function DemandaItens() {
    const [Demanda, setDemanda] = useState({ cnpj: '', num: '', dataConclusao: '', requisitante: '', objeto: '', prioridade: '', justificativaPrioridade: '', justificativaDemanda: '', tipo: '', usuario: '' });
    const [DemandaItens, setDemandaItens] = useState([]);
    const [unidades, setUnidades] = useState([]);
    const [itemToDelete, setItemToDelete] = useState(null);
    const [shouldFetch, setShouldFetch] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(5); // Quantidade de linhas por página
    const [startIndex, setStartIndex] = useState(0); // Índice inicial dos itens exibidos
    const token = localStorage.getItem('token');
    const navigate = useNavigate();
    const location = useLocation();
    const modalRef = useRef(null);
    const saveModalRef = useRef(null);

    useEffect(() => {
        if (location.state && location.state.dfd) {
            setDemanda(location.state.dfd);
        }
        if (Demanda.cnpj !== '') {
            axios.get(`${API_BASE_URL.DFD_ITEM}/dfd`, {
                params: {
                    cnpj: Demanda.cnpj,
                    numDfd: Demanda.num
                },
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(result => {
                const formattedData = result.data.map(item => ({
                    ...item,
                    vlrUnit: formatCurrency(item.vlrUnit),
                }));
                setDemandaItens(formattedData);
            }).catch(error => {
                console.error('Erro ao obter listas:', error);
            });

            axios.get(`${API_BASE_URL.UNIDADE_MEDIDA}/all`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(result => {
                setUnidades(result.data);
            }).catch(error => {
                console.error('Erro ao obter unidades:', error);
            });
        }

    }, [location.state, Demanda, shouldFetch]);

    if (!DemandaItens) {
        return <div>Loading...</div>;
    }
    //Início da Paginação
    const endIndex = startIndex + rowsPerPage;
    const selectedDemandaItens = DemandaItens.slice(startIndex, endIndex);
    const totalItems = DemandaItens.length;

    useEffect(() => {
        setStartIndex(0);
    }, [rowsPerPage]);

    function handleDelete(dfd) {
        setItemToDelete(dfd);
        const modal = new window.bootstrap.Modal(modalRef.current);
        modal.show();
    }

    function handleDeleteConfirm() {
        axios.delete(`${API_BASE_URL.DFD_ITEM}/delete`, {
            params: {
                cnpj: itemToDelete.cnpj,
                numDfd: itemToDelete.numDfd,
                grupo: itemToDelete.grupo,
                classe: itemToDelete.classe,
                codigo: itemToDelete.codigo
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(result => {
            setShouldFetch(!shouldFetch);
            setItemToDelete(null);
            const modal = window.bootstrap.Modal.getInstance(modalRef.current);
            modal.hide();
        })
            .catch(error => {
                console.error('Erro ao excluir o item:', error);
            });
    }

    function formatCurrency(value) {
        if (isNaN(value)) return value;
        return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
    }

    function unformatCurrency(value) {
        return value.replace(/\D/g, '') / 100;
    }

    function calculateTotal(quantidade, vlrUnit) {
        const quantidadeNum = parseFloat(quantidade) || 0;
        const vlrUnitNum = unformatCurrency(vlrUnit) || 0;
        return formatCurrency(quantidadeNum * vlrUnitNum);
    }

    const handleInputChange = (index, field, value) => {
        const updatedDemandaItens = [...DemandaItens];
        if (field === 'vlrUnit') {
            updatedDemandaItens[index][field] = formatCurrency(unformatCurrency(value));
        } else {
            updatedDemandaItens[index][field] = value;
        }
        setDemandaItens(updatedDemandaItens);
    };

    const handleSaveAll = () => {
        const itemsToSave = DemandaItens.map(item => ({
            ...item,
            vlrUnit: unformatCurrency(item.vlrUnit),
        }));
        console.log("ITENS: ", itemsToSave);
        axios.post(`${API_BASE_URL.DFD_ITEM}/saveAll`, itemsToSave, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(result => {
            console.log('Itens atualizados com sucesso:', result.data);
            const modal = new window.bootstrap.Modal(saveModalRef.current);
            modal.show();
        }).catch(error => {
            console.error('Erro ao atualizar os itens:', error);
        });
    };

    return (
        <div className='full-screen-div'>
            <Navbar />
            <div className="container mt-4 pt-5">
                <ResponsiveShadow className="container mt-4 mb-4">
                    <main role="main" className="col-md-12 ml-sm-auto col-lg-12 px-md-4">
                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-1 mb-2 border-bottom">
                            <h3 className="h3">Itens da Demanda E-fisco/PE</h3>
                        </div>
                        <div className="d-flex justify-content-start mb-1">
                            <Link to="/demandas_efisco_pe" state={{ dfd: Demanda }} className='btn btn-secondary btn-sm me-2'>
                                <i className="bi bi-arrow-left" /> Voltar</Link>
                            <Link to="/demanda_item_find_efisco_pe" state={{ dfd: Demanda }} className='btn btn-success btn-sm me-2'>
                                <i className="bi bi-plus-lg" /> Adicionar Item
                            </Link>
                            <button onClick={handleSaveAll} className='btn btn-primary btn-sm'>
                                <i className="bi bi-save" /> Salvar Todos
                            </button>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-hover table-sm">
                                <thead className="table-secondary">
                                    <tr>
                                        <th scope="col" className="text-center align-middle">Grupo</th>
                                        <th scope="col" className="text-center align-middle">Classe</th>
                                        <th scope="col" className="text-center align-middle">Código</th>
                                        <th scope="col" className="text-center align-middle">Descrição</th>
                                        <th scope="col" className="text-center align-middle">Unidade</th>
                                        <th scope="col" className="text-center align-middle">Quantidade</th>
                                        <th scope="col" className="text-center align-middle">Valor Unitário</th>
                                        <th scope="col" className="text-center align-middle">Valor Total</th>
                                        <th scope="col" className="text-center align-middle">Ações</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedDemandaItens.map((c, index) => (
                                        <tr key={`${c.cnpj}-${c.numDfd}-${c.grupo}-${c.classe}-${c.codigo}`}>
                                            <td>{c.grupo}</td>
                                            <td>{c.classe}</td>
                                            <td>{c.codigo}</td>
                                            <td>{c.matItemDescricao.descricao}</td>
                                            <td>
                                                <select
                                                    name="unidade"
                                                    value={c.unidade}
                                                    onChange={(e) => handleInputChange(index, 'unidade', e.target.value)}
                                                    className="form-select form-select-sm" // Bootstrap class for small-sized select
                                                >   <option value="">Selecione...</option>
                                                    {unidades.map(unidade => (
                                                        <option key={unidade.id} value={unidade.descricao}>{unidade.descricao}</option>
                                                    ))}
                                                </select>
                                            </td>
                                            <td className="text-end">
                                                <input
                                                    type="number"
                                                    name="quantidade"
                                                    value={c.quantidade}
                                                    onChange={(e) => handleInputChange(index, 'quantidade', e.target.value)}
                                                    className="form-control form-control-sm text-end" // Bootstrap class for small-sized input
                                                    min="0" // Optional: Ensures the input is non-negative
                                                />
                                            </td>
                                            <td className="text-end">
                                                <input
                                                    type="text"
                                                    name="vlrUnit"
                                                    value={c.vlrUnit}
                                                    onChange={(e) => handleInputChange(index, 'vlrUnit', e.target.value)}
                                                    className="form-control form-control-sm text-end" // Bootstrap class for small-sized input
                                                    min="0" // Optional: Ensures the input is non-negative
                                                />
                                            </td>
                                            <td className="text-end">
                                                {calculateTotal(c.quantidade, c.vlrUnit)}
                                            </td>
                                            <td>
                                                <button onClick={() => handleDelete(c)} className='btn btn-danger btn-sm'>
                                                    <i className="bi bi-trash3" />Excluir
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <Pagination
                                totalItems={totalItems}
                                rowsPerPage={rowsPerPage}
                                startIndex={startIndex}
                                onRowsPerPageChange={(e) => setRowsPerPage(Number(e.target.value))}
                                onFirstItems={() => setStartIndex(0)}
                                onPreviousItems={() => setStartIndex(Math.max(startIndex - rowsPerPage, 0))}
                                onNextItems={() => setStartIndex(Math.min(startIndex + rowsPerPage, totalItems - rowsPerPage))}
                                onLastItems={() => setStartIndex(Math.max(totalItems - rowsPerPage, 0))}
                            />
                        </div>
                    </main>
                </ResponsiveShadow>
            </div>

            <div className="modal fade" id="deleteConfirmationModal" tabIndex="-1" aria-labelledby="deleteConfirmationModalLabel" aria-hidden="true" ref={modalRef}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="deleteConfirmationModalLabel">Confirmar Exclusão</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            Tem certeza que deseja excluir este item?
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                            <button type="button" className="btn btn-danger" onClick={handleDeleteConfirm}>Excluir</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="saveConfirmationModal" tabIndex="-1" aria-labelledby="saveConfirmationModalLabel" aria-hidden="true" ref={saveModalRef}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="saveConfirmationModalLabel">Confirmação</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            Os dados foram salvos com sucesso!
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">OK</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DemandaItens;
