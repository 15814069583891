import React from 'react';

function LoadingModal({ loading }) {
    if (!loading) return null; // Não renderiza nada se loading for false

    return (
        <div className="modal fade show d-flex justify-content-center align-items-center" style={{ display: 'block' }} tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-body text-center">
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Carregando...</span>
                        </div>
                        <p>Carregando dados, por favor aguarde...</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoadingModal;
