import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_BASE_URL } from './ApiConfig';
import Navbar from './components/Navbar';
import ResponsiveShadow from './components/ResponsiveShadow';

const UploadTemplate = () => {
    const [file, setFile] = useState(null);
    const [orientacao, setOrientacao] = useState('');
    const [message, setMessage] = useState('');
    const [appCliente, setAppCliente] = useState(null);
    const navigate = useNavigate();
    const token = localStorage.getItem('token');
    const fileInputRef = useRef(null);
    useEffect(() => {
        if (localStorage.getItem('token') === null) {
            navigate('/');
        }
        const appClienteData = localStorage.getItem('appCliente');
        if (appClienteData) {
            // console.log('O que veio? ', appClienteData);
            setAppCliente(JSON.parse(appClienteData));
        }
    }, [navigate]);
    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleNomeChange = (e) => {
        setOrientacao(e.target.value);
    };

    const handleUpload = async (e) => {
        e.preventDefault();

        if (!file || !orientacao) {
            setMessage('Por favor, forneça um nome e selecione um arquivo.');
            return;
        }

        const formData = new FormData();
        formData.append('file', file);
        formData.append('nome', orientacao);
        formData.append('cnpj', appCliente.cnpj);


        try {
            const response = await axios.post(`${API_BASE_URL.TEMPLATE}/save`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`
                },
            });
            setMessage(response.data);
            setOrientacao('');
            setFile(null);
            if (fileInputRef.current) {
                fileInputRef.current.value = "";
            }
        } catch (error) {
            console.error('Erro ao enviar o template:', error);
            setMessage('Erro ao enviar o template.');
        }
    };

    return (
        <div className='full-screen-div'>
            <Navbar />
            <div className="container mt-4 pt-5">
                <ResponsiveShadow className="container mt-4 mb-4">
                    <main role="main" className="col-md-12 ml-sm-auto col-lg-12 px-md-4">
                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                            <h3 className="h3">Envio de Template</h3>
                        </div>
                        <div className="col-md-6 ml-sm-auto">
                            <form onSubmit={handleUpload}>
                                <div className='col-md-12 input-group-sm'>
                                    <label className='form-label'>Nome do Template</label>
                                    <select onChange={handleNomeChange} value={orientacao} name='nome' className='form-select'>
                                        <option value="">Selecione...</option>
                                        <option value="DEMANDA E-FISCO">DEMANDA E-FISCO</option>
                                        <option value="DEMANDA CATMAT">DEMANDA CATMAT</option>
                                        <option value="DEMANDA CATSER">DEMANDA CATSER</option>
                                        <option value="DFD E-FISCO">DFD E-FISCO</option>
                                        <option value="DFD CATMAT">DFD CATMAT</option>
                                        <option value="DFD CATSER">DFD CATSER</option>
                                    </select>
                                </div>
                                <div className='col-md-12 mt-1 input-group-sm'>
                                    <label className='form-label'>Selecione o Arquivo</label>
                                    <input className='form-control' type="file" onChange={handleFileChange} name="file" ref={fileInputRef} />
                                </div>
                                <div className="form-group mb-3 mt-2">
                                    <Link className="btn btn-secondary btn-sm mx-1" to="/home">Voltar</Link>
                                    <button className='btn btn-success btn-sm mx-1' type="submit">Enviar</button>
                                </div>
                            </form>
                            {message && <p>{message}</p>}
                        </div>
                    </main>
                </ResponsiveShadow>
            </div>
        </div>
    );
};

export default UploadTemplate;
