import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './css/Cliente.css';
import axios from 'axios';
import Navbar from './components/Navbar';
import { API_BASE_URL } from './ApiConfig';
import 'bootstrap-icons/font/bootstrap-icons.css';
import LoadingModal from './components/LoadingModal';
import ErrorModal from './components/ErrorModal';
import Pagination from './components/Pagination';
import ConfirmDeleteModal from './components/ConfirmDeleteModal';
import { formatDateView } from './utils/dateUtils';
import ResponsiveShadow from './components/ResponsiveShadow';

function CatserDemandas() {
    const [CatserDfds, setCatserDfds] = useState([]);
    const [itemToDelete, setItemToDelete] = useState(null);
    const [shouldFetch, setShouldFetch] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [startIndex, setStartIndex] = useState(0);
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

    const [modalMessage, setModalMessage] = useState('');
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');

    const token = localStorage.getItem('token');
    const cnpj = localStorage.getItem('cnpj');
    const navigate = useNavigate();

    useEffect(() => {
        setIsLoading(true);
        axios.get(`${API_BASE_URL.CATSER_DFD}/cnpj`, {
            params: { cnpj },
            headers: { Authorization: `Bearer ${token}` }
        })
            .then(result => {
                setCatserDfds(result.data);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Erro ao obter listas:', error);
                setIsLoading(false); // Desativa o loading mesmo em caso de erro
                navigate('/');
            });
    }, [shouldFetch, cnpj, token, navigate]);

    const endIndex = startIndex + rowsPerPage;
    const selectedDemandas = CatserDfds.slice(startIndex, endIndex);
    const totalItems = CatserDfds.length;

    function handleRowsPerPageChange(event) {
        setRowsPerPage(Number(event.target.value));
        setStartIndex(0);
    }

    function handleNextItems() {
        const newStartIndex = Math.min(startIndex + rowsPerPage, totalItems - rowsPerPage);
        setStartIndex(newStartIndex);
    }

    function handlePreviousItems() {
        const newStartIndex = Math.max(startIndex - rowsPerPage, 0);
        setStartIndex(newStartIndex);
    }

    function handleFirstItems() {
        setStartIndex(0);
    }

    function handleLastItems() {
        const newStartIndex = Math.max(totalItems - rowsPerPage, 0);
        setStartIndex(newStartIndex);
    }

    // function handleDelete(dfd) {
    //     setItemToDelete(dfd);
    //     const modal = new window.bootstrap.Modal(document.getElementById('deleteConfirmationModal'));
    //     modal.show();
    // }
    const openDeleteModal = (item) => {
        setItemToDelete(item);
        setShowConfirmDeleteModal(true);
    };

    const handleDeleteConfirm = () => {
        setShowConfirmDeleteModal(false);
        if (itemToDelete) {
            axios.get(`${API_BASE_URL.CATSER_DFD_ITEM}/exists`, {
                params: { cnpj: itemToDelete.cnpj, dfd: itemToDelete.dfd },
                headers: { Authorization: `Bearer ${token}` }
            }).then(result => {
                if (result.data.exists) {
                    setModalMessage(
                        "Esta Demanda possui vínculos e não pode ser excluída. Exclua os vínculos antes de tentar novamente."
                    );
                    setShowErrorModal(true);
                } else {
                    axios.delete(`${API_BASE_URL.CATSER_DFD}/delete`, {
                        params: {
                            cnpj: itemToDelete.cnpj,
                            dfd: itemToDelete.dfd
                        },
                        headers: { Authorization: `Bearer ${token}` }
                    })
                        .then(() => {
                            setCatserDfds(prevState =>
                                prevState.filter(item => item.dfd !== itemToDelete.dfd)
                            );
                            setShowConfirmDeleteModal(false);
                        })
                        .catch(error => {
                            console.error('Erro ao excluir o item:', error);
                        });
                }
            });
        }
    }


    async function handlePrint(dfd) {
        setLoading(true);
        try {
            const response = await axios.post(
                `${API_BASE_URL.CATSER_DFD}/preencherDfd`,
                dfd,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                    responseType: 'blob',
                }
            );

            const blob = new Blob([response.data], {
                type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `DEMANDA CATSER ${dfd.dfd}.docx`;
            link.click();

            setMessage('Documento preenchido com sucesso!');
        } catch (error) {
            console.error('Erro ao preencher o template:', error);
            setMessage('Erro ao preencher o template.');
        } finally {
            setLoading(false);
        }
    }


    return (
        <div className='full-screen-div'>
            <Navbar />
            <div className="container mt-4 pt-5">
                <ResponsiveShadow className="container mt-4 mb-4">
                    <main role="main" className="col-md-12 ml-sm-auto col-lg-12 px-md-4">
                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-1 mb-2 border-bottom">
                            <h3 className="h3">Demandas CATSER</h3>
                        </div>
                        <div className="d-flex justify-content-start mb-2">
                            <Link className="btn btn-secondary btn-sm me-2" to="/home">
                                <i className="bi bi-house" /></Link>
                            <Link to="/demanda_catser" className='btn btn-success btn-sm'>
                                <i className="bi bi-plus-lg" /> Novo
                            </Link>
                        </div>
                        <LoadingModal loading={isLoading} />
                        {!isLoading && (
                            <>
                                <div className="table-responsive-sm">
                                    <table className="table table-hover table-striped table-sm">
                                        <thead className="table-secondary">
                                            <tr>
                                                <th scope="col" className="align-middle">Nº</th>
                                                <th scope="col" className="align-middle">Data Criação</th>
                                                <th scope="col" className="align-middle">Área Requisitante</th>
                                                <th scope="col" className="align-middle">Objeto</th>
                                                {/* <th scope="col" className="text-center align-middle">Ações</th> */}
                                                <th scope="col" className="text-center align-middle" style={{ width: '35%', whiteSpace: 'nowrap' }}>Ações</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {selectedDemandas.map(c => (
                                                <tr key={`${c.cnpj}-${c.dfd}`}>
                                                    <td>{c.dfd}</td>
                                                    <td>{formatDateView(c.dataCriacao)}</td>
                                                    <td>{c.requisitante}</td>
                                                    <td>{c.objeto}</td>
                                                    <td>

                                                        <Link to="/demanda_catser" state={{ dfd: c }} className='btn btn-primary btn-sm mx-1 my-1'>
                                                            <i className="bi bi-pencil-fill me-1" /> Editar
                                                        </Link>
                                                        <Link to="/demanda_catser_itens" state={{ dfd: c }} className='btn btn-success btn-sm mx-1 my-1'>
                                                            <i className="bi bi-boxes me-1" /> Itens
                                                        </Link>
                                                        <button onClick={() => handlePrint(c)} className='btn btn-secondary btn-sm mx-1 my-1'>
                                                            <i className="bi bi-printer me-1" /> Imprimir
                                                        </button>
                                                        <button onClick={() => openDeleteModal(c)} className='btn btn-danger btn-sm mx-1 my-1'>
                                                            <i className="bi bi-trash3 me-1" /> Excluir
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <Pagination
                                        totalItems={totalItems}
                                        rowsPerPage={rowsPerPage}
                                        startIndex={startIndex}
                                        onRowsPerPageChange={handleRowsPerPageChange}
                                        onFirstItems={handleFirstItems}
                                        onPreviousItems={handlePreviousItems}
                                        onNextItems={handleNextItems}
                                        onLastItems={handleLastItems}
                                    />
                                </div>
                            </>
                        )}
                    </main>
                </ResponsiveShadow>
            </div>
            <ConfirmDeleteModal
                show={showConfirmDeleteModal}
                onClose={() => setShowConfirmDeleteModal(false)}
                onConfirm={handleDeleteConfirm}
            />
            <ErrorModal show={showErrorModal} message={modalMessage} onClose={() => setShowErrorModal(false)} />
        </div>
    );
}

export default CatserDemandas;
