import { useState } from 'react';
import axios from 'axios';
import Navbar from './components/Navbar';
import CPFMaskInput from './components/CPFMaskInput';
import { API_BASE_URL } from './ApiConfig';
import { Link, useLocation } from 'react-router-dom';
import { unformatCPF, formatCPF, isValidCPF } from './utils/cpfUtils'; // Importa a função de validação
import ResponsiveShadow from './components/ResponsiveShadow';

function Usuario() {
    const [Usuario, setUsuario] = useState({ cpf: '', nome: '', login: '', senha: '', situacao: '', role: '' });
    const [cpfError, setCpfError] = useState(''); // Estado para armazenar erro de CPF
    const token = localStorage.getItem('token');
    const location = useLocation();

    useState(() => {
        if (location.state && location.state.usuario) {
            setUsuario(location.state.usuario);
        }
    }, [location.state]);

    function handleChange(event) {
        const { name, value } = event.target;
        setUsuario({ ...Usuario, [name]: value });
    }

    function handleSubmit(event) {
        event.preventDefault();

        // Valida o CPF antes de enviar
        if (!isValidCPF(Usuario.cpf)) {
            setCpfError('CPF inválido');
            return;
        }
        setCpfError(''); // Limpa o erro se o CPF for válido

        const usuarioParaSalvar = {
            ...Usuario,
            cpf: unformatCPF(Usuario.cpf) // Remove a formatação antes de enviar
        };

        axios.post(`${API_BASE_URL.USUARIO}/salvar`, usuarioParaSalvar, {
            headers: { Authorization: `Bearer ${token}` }
        }).then(() => {
            window.location.href = '/usuarios';
        });
    }

    return (
        <div className='full-screen-div'>
            <Navbar />
            <div className="container mt-4 pt-5">
                <ResponsiveShadow className="container mt-4 mb-4">
                    <main role="main" className="col-md-12 ml-sm-auto col-lg-12 px-md-4">
                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                            <h3 className="h3">Cadastro de Usuário</h3>
                        </div>
                        <div className="col-md-6 ml-sm-auto">
                            <form onSubmit={handleSubmit}>
                                <div className='row g-3'>
                                    <div className='col-md-4 input-group-sm'>
                                        <label className='form-label'>CPF</label>
                                        <CPFMaskInput
                                            value={formatCPF(Usuario.cpf)} // Exibe o CPF formatado
                                            onChange={(e) =>
                                                handleChange({ target: { name: 'cpf', value: unformatCPF(e.target.value) } })
                                            }
                                            name="cpf"
                                            type="text"
                                        />
                                        {cpfError && <small className="text-danger">{cpfError}</small>}
                                    </div>
                                    <div className='col-md-8 input-group-sm'>
                                        <label className='form-label'>Nome</label>
                                        <input onChange={handleChange} value={Usuario.nome} name='nome' type='text' className='form-control'></input>
                                    </div>
                                    <div className='col-md-4 input-group-sm'>
                                        <label className='form-label'>Login</label>
                                        <input onChange={handleChange} value={Usuario.login} name='login' type='text' className='form-control'></input>
                                    </div>
                                    <div className='col-md-4 input-group-sm'>
                                        <label className='form-label'>Senha</label>
                                        <input onChange={handleChange} value={Usuario.senha} name='senha' type='password' className='form-control'></input>
                                    </div>
                                    <div className='col-md-4 input-group-sm'>
                                        <label className='form-label'>Situação</label>
                                        <select onChange={handleChange} value={Usuario.situacao} name='situacao' className='form-select'>
                                            <option value=""></option>
                                            <option value="ATIVO">ATIVO</option>
                                            <option value="INATIVO">INATIVO</option>
                                        </select>
                                    </div>
                                    <div className='col-md-4 input-group-sm'>
                                        <label className='form-label'>Tipo de Usuário</label>
                                        <select onChange={handleChange} value={Usuario.role} name='role' className='form-select'>
                                            <option value=""></option>
                                            <option value="ADMIN">ADMINISTRADOR</option>
                                            <option value="USER">USUÁRIO</option>
                                        </select>
                                    </div>
                                    <div className='col-md-12 mb-3 input-group-sm'>
                                        <Link to="/usuarios" className='btn btn-secondary mx-1'><i className="bi bi-arrow-left" /> Voltar</Link>
                                        <Link to="/usuarios" className='btn btn-success mx-1' onClick={handleSubmit}><i className="bi bi-check-lg" /> Salvar</Link>
                                    </div>
                                </div>
                            </form >
                        </div>
                    </main>
                </ResponsiveShadow>
            </div>
        </div>
    );
}

export default Usuario;
