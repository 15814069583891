import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './css/Cliente.css';
import axios from 'axios';
import Navbar from './components/Navbar';
import { API_BASE_URL } from './ApiConfig';
import ConfirmDeleteModal from './components/ConfirmDeleteModal';
import ErrorModal from './components/ErrorModal';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { formatDateView } from './utils/dateUtils';
import Pagination from './components/Pagination';
import ResponsiveShadow from './components/ResponsiveShadow';

function Demandas() {
    const [Demandas, setDemandas] = useState([]);
    const [itemToDelete, setItemToDelete] = useState(null);
    const [modalMessage, setModalMessage] = useState('');
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [startIndex, setStartIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const token = localStorage.getItem('token');
    const cnpj = localStorage.getItem('cnpj');
    const navigate = useNavigate();

    useEffect(() => {
        axios.get(`${API_BASE_URL.DFD}/cnpj`, {
            params: { cnpj: cnpj },
            headers: { Authorization: `Bearer ${token}` }
        }).then(result => {
            setDemandas(result.data);
        }).catch(error => {
            console.error('Erro ao obter listas:', error);
            navigate('/');
        });
    }, []);

    if (!Demandas) {
        return <div>Loading...</div>;
    }

    const endIndex = startIndex + rowsPerPage;
    const selectedDemandas = Demandas.slice(startIndex, endIndex);
    const totalItems = Demandas.length;

    useEffect(() => {
        setStartIndex(0);
    }, [rowsPerPage]);

    function handleDelete(dfd) {
        setItemToDelete(dfd);
        setShowConfirmDeleteModal(true);
    }

    function handleDeleteConfirm() {
        setShowConfirmDeleteModal(false);
        if (itemToDelete) {
            axios.get(`${API_BASE_URL.DFD_ITEM}/exists`, {
                params: { cnpj: itemToDelete.cnpj, num: itemToDelete.num },
                headers: { Authorization: `Bearer ${token}` }
            }).then(result => {
                if (result.data.exists) {
                    setModalMessage(
                        "Esta Demanda possui vínculos e não pode ser excluída. Exclua os vínculos antes de tentar novamente."
                    );
                    setShowErrorModal(true);
                } else {
                    axios.delete(`${API_BASE_URL.DFD}/delete`, {
                        params: {
                            cnpj: itemToDelete.cnpj,
                            num: itemToDelete.num
                        },
                        headers: { Authorization: `Bearer ${token}` }
                    }).then(result => {
                        setDemandas(prevState =>
                            prevState.filter(item => item.num !== itemToDelete.num)
                        );
                        setShowConfirmDeleteModal(false);
                    }).catch(error => {
                        console.error('Erro ao excluir o item:', error);
                    });
                }
            });
        }
    }

    async function handlePrint(dfd) {
        setIsLoading(true);
        try {
            const response = await axios.post(
                `${API_BASE_URL.DFD}/preencherDfd`,
                dfd,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                    responseType: 'blob',
                }
            );

            const blob = new Blob([response.data], {
                type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `DEMANDA E-FISCO ${dfd.num}.docx`;
            link.click();
        } catch (error) {
            console.error('Erro ao preencher o template:', error);
            setModalMessage('Erro ao preencher o template.');
            setShowErrorModal(true);
        } finally {
            setIsLoading(false);
        }
    }


    return (
        <div className='full-screen-div'>
            <Navbar />
            <div className="container mt-4 pt-5">
                <ResponsiveShadow className="container mt-4 mb-4">
                    <main role="main" className="col-md-12 ml-sm-auto col-lg-12 px-md-4">
                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-1 mb-2 border-bottom">
                            <h3 className="h3">Demandas E-fisco/PE</h3>
                        </div>
                        <div className="d-flex justify-content-start mb-2">
                            <Link className="btn btn-secondary btn-sm me-2" to="/home">
                                <i className="bi bi-house" /></Link>
                            <Link to="/demanda_efisco_pe" className='btn btn-success btn-sm'>
                                <i className="bi bi-plus-lg" /> Novo
                            </Link>
                        </div>
                        <div className="table-responsive-sm">
                            <table className="table table-hover table-striped table-sm">
                                <thead className="table-secondary">
                                    <tr>
                                        <th scope="col" className="align-middle">Nº</th>
                                        <th scope="col" className="align-middle">Data Criação</th>
                                        <th scope="col" className="align-middle">Área Requisitante</th>
                                        <th scope="col" className="align-middle">Objeto</th>
                                        <th scope="col" className="align-middle" style={{ width: '35%', whiteSpace: 'nowrap' }}>Ações</th>
                                        {/* <th scope="ACOES" style={{ width: '1%', whiteSpace: 'nowrap' }}>Ações</th> Ajuste aqui */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedDemandas.map(c => (
                                        <tr key={`${c.cnpj}-${c.num}`}>
                                            <td>{c.num}</td>
                                            <td>{formatDateView(c.dataCriacao)}</td>
                                            <td>{c.requisitante}</td>
                                            <td>{c.objeto}</td>
                                            <td>
                                                <Link to="/demanda_efisco_pe" state={{ dfd: c }} className='btn btn-primary btn-sm flex-fill mx-1 my-1 btn-block'>
                                                    <i className="bi bi-pencil-fill me-1" /> Editar
                                                </Link>
                                                <Link to="/demanda_itens_efisco_pe" state={{ dfd: c }} className='btn btn-success btn-sm flex-fill mx-1 my-1 btn-block'>
                                                    <i className="bi bi-boxes me-1" /> Itens
                                                </Link>
                                                <button onClick={() => handlePrint(c)} className='btn btn-secondary btn-sm flex-fill mx-1 my-1 btn-block'>
                                                    <i className="bi bi-printer me-1" /> Imprimir
                                                </button>
                                                <button onClick={() => handleDelete(c)} className='btn btn-danger btn-sm flex-fill mx-1 my-1 btn-block'>
                                                    <i className="bi bi-trash3 me-1" /> Excluir
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <Pagination
                                totalItems={totalItems}
                                rowsPerPage={rowsPerPage}
                                startIndex={startIndex}
                                onRowsPerPageChange={(e) => setRowsPerPage(Number(e.target.value))}
                                onFirstItems={() => setStartIndex(0)}
                                onPreviousItems={() => setStartIndex(Math.max(startIndex - rowsPerPage, 0))}
                                onNextItems={() => setStartIndex(Math.min(startIndex + rowsPerPage, totalItems - rowsPerPage))}
                                onLastItems={() => setStartIndex(Math.max(totalItems - rowsPerPage, 0))}
                            />
                        </div>
                    </main>
                </ResponsiveShadow>
            </div>
            <ConfirmDeleteModal
                show={showConfirmDeleteModal}
                onClose={() => setShowConfirmDeleteModal(false)}
                onConfirm={handleDeleteConfirm}
            />
            <ErrorModal
                show={showErrorModal}
                onClose={() => setShowErrorModal(false)}
                message={modalMessage}
            />
        </div>
    );
}

export default Demandas;
