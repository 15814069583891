import React, { useState, useEffect, useRef } from 'react';
import './css/Item.css';
import axios from 'axios';
import Navbar from './components/Navbar';
import { API_BASE_URL } from './ApiConfig';
import { Link, useLocation } from 'react-router-dom';
import LoadingModal from './components/LoadingModal';  // Componente de loading
import ErrorModal from './components/ErrorModal';  // Componente de erro
import SuccessModal from './components/SuccessModal';
import Pagination from './components/Pagination';
import ResponsiveShadow from './components/ResponsiveShadow';

function CatmatDemandaItemFind() {
    const [Dfd, setDfd] = useState({ cnpj: '', dfd: '', dataConclusao: '', requisitante: '', objeto: '', prioridade: '', justificativaPrioridade: '', justificativaDemanda: '', tipo: '', usuario: '' });
    const [Item, setItem] = useState({ grupo: '', classe: '', pdm: '', item: '', descricao: '', situacao: '' });
    const [Itens, setItens] = useState([]);
    const [opcaoPesquisa, setOpcaoPesquisa] = useState('inicioTexto');
    const location = useLocation();
    const token = localStorage.getItem('token');
    const [rowsPerPage, setRowsPerPage] = useState(5); // Quantidade de linhas por página
    const [startIndex, setStartIndex] = useState(0); // Índice inicial dos itens exibidos
    const [modalMessage, setModalMessage] = useState(''); // Mensagem do modal de erro
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false); // Estado de carregamento
    const modalRef = useRef(null); // Definição do useRef para o modal

    function handleChange(event) {
        setItem({ ...Item, [event.target.name]: event.target.value.toUpperCase() });
    }

    function handleOpcaoPesquisaChange(event) {
        setOpcaoPesquisa(event.target.value);
    }

    useEffect(() => {
        setStartIndex(0);
    }, [rowsPerPage]);

    function handleAdd(p) {
        const newDfdItem = {
            cnpj: Dfd.cnpj,
            dfd: Dfd.dfd,
            grupo: p.grupo,
            classe: p.classe,
            pdm: p.pdm,
            item: p.item,
            unidade: '',
            quantidade: '',
            vlr_unit: ''
        };

        axios.post(`${API_BASE_URL.CATMAT_DFD_ITEM}/save`, newDfdItem, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(response => {
                console.log('Item salvo com sucesso:', response.data);
                // Mostrar o modal de confirmação
                setModalMessage('Item salvo com sucesso!');
                setShowSuccessModal(true);
            })
            .catch(error => {
                console.error('Erro ao salvar o item:', error);
            });
    }

    useEffect(() => {
        if (location.state && location.state.dfd) {
            setDfd(location.state.dfd);
        }
    }, [location.state]);

    const endIndex = startIndex + rowsPerPage;
    const selectedItens = Itens.slice(startIndex, endIndex);
    const totalItems = Itens.length;

    function handleFind() {
        if (!Item.descricao) {
            return;
        }

        setIsLoading(true); // Inicia o carregamento
        axios.get(`${API_BASE_URL.CATMAT_ITEM}/pesquisar_itens`, {
            params: {
                descricao: Item.descricao,
                opcaoPesquisa: opcaoPesquisa
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(result => {
                setItens(result.data);
            })
            .catch(error => {
                console.error('Erro ao buscar itens:', error);
            })
            .finally(() => {
                setIsLoading(false); // Termina o carregamento
            });
    }

    function handleClear() {
        setItem({ grupo: '', classe: '', codigo: '', descricao: '', situacao: '' });
        setItens([]);
    }

    return (
        <div className='full-screen-div'>
            <Navbar />
            <div className="container mt-4 pt-5">
                <ResponsiveShadow className="container mt-4 mb-4">
                    <main role="main" className="col-md-12 ml-sm-auto col-lg-12 px-md-4">
                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                            <h3 className="h3">Pesquisa Item CATMAT</h3>
                        </div>
                        <form>
                            <div className='row g-2'>
                                <div className='col-md-4 input-group-sm'>
                                    <label className='form-label'>Opções de pesquisa:</label>
                                    <div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="opcaoPesquisa" id="inicioTexto" value="inicioTexto" checked={opcaoPesquisa === 'inicioTexto'} onChange={handleOpcaoPesquisaChange} />
                                            <label className="form-check-label" htmlFor="inicioTexto">Início do Texto</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="opcaoPesquisa" id="qualquerParteTexto" value="qualquerParteTexto" checked={opcaoPesquisa === 'qualquerParteTexto'} onChange={handleOpcaoPesquisaChange} />
                                            <label className="form-check-label" htmlFor="qualquerParteTexto">Em qualquer parte do Texto</label>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-8 input-group-sm'>
                                    <label className='form-label'>Descrição:</label>
                                    <input onChange={handleChange} value={Item.descricao} onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()} name='descricao' type='text' className='form-control'></input>
                                </div>
                                <div className='col-md-2 input-group mb-2'>
                                    <Link to="/demanda_catmat_itens" state={{ dfd: Dfd }} className='btn btn-secondary btn-sm mx-1'><i className="bi bi-arrow-left" /> Voltar</Link>
                                    <input onClick={() => handleFind(Item.descricao)} type='button' value='Pesquisar' className='btn btn-success btn-sm'></input>
                                    <input onClick={() => handleClear()} type='button' value='Limpar' className='btn btn-secondary btn-sm mx-1'></input>
                                </div>
                            </div>
                        </form>
                        {/* Modal de Carregamento */}
                        <LoadingModal loading={isLoading} />

                        {/* Conteúdo da página só aparece após o carregamento */}
                        {!isLoading && (
                            <>
                                <div className="table-responsive-sm">
                                    <table className="table table-hover table-striped table-sm">
                                        <thead className="table-secondary">
                                            <tr>
                                                <th scope="GRUPO">Grupo</th>
                                                <th scope="CLASSE">Classe</th>
                                                <th scope="CODIGO">Código</th>
                                                <th scope="DESCRICAO">Descrição</th>
                                                <th scope="SITUACAO">Situação</th>
                                                <th scope="ACAO">Ação</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {selectedItens.map(i => (
                                                <tr key={`${i.grupo}-${i.classe}-${i.pdm}-${i.item}`}>
                                                    <td>{i.item}</td>
                                                    <td>{i.classe}</td>
                                                    <td>{i.item}</td>
                                                    <td>{i.descricao}</td>
                                                    <td>{i.situacao}</td>
                                                    <td>
                                                        <button onClick={() => handleAdd(i)} className='btn btn-primary btn-sm'>Adicionar</button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <Pagination
                                        totalItems={totalItems}
                                        rowsPerPage={rowsPerPage}
                                        startIndex={startIndex}
                                        onRowsPerPageChange={(e) => setRowsPerPage(Number(e.target.value))}
                                        onFirstItems={() => setStartIndex(0)}
                                        onPreviousItems={() => setStartIndex(Math.max(startIndex - rowsPerPage, 0))}
                                        onNextItems={() => setStartIndex(Math.min(startIndex + rowsPerPage, totalItems - rowsPerPage))}
                                        onLastItems={() => setStartIndex(Math.max(totalItems - rowsPerPage, 0))}
                                    />
                                </div>
                            </>
                        )}
                    </main>
                </ResponsiveShadow>
            </div>

            <ErrorModal
                show={showErrorModal}
                message={modalMessage}
                onClose={() => setShowErrorModal(false)}
            />
            <SuccessModal
                show={showSuccessModal}
                message={modalMessage}
                onClose={() => setShowSuccessModal(false)}
            />
        </div>
    );
}

export default CatmatDemandaItemFind;
