import React from 'react';
import { useState } from 'react';
import './css/Item.css';
import axios from 'axios';
import Navbar from './components/Navbar';
import { API_BASE_URL } from './ApiConfig';
import ResponsiveShadow from './components/ResponsiveShadow';

function Item() {
    const [item, setItem] = useState({ grupo: '', classe: '', codigo: '', descricao: '', situacao: '' });
    const [itens, setItens] = useState([]);
    const [opcaoPesquisa, setOpcaoPesquisa] = useState('inicioTexto');
    const token = localStorage.getItem('token');

    function handleChange(event) {
        setItem({ ...item, [event.target.name]: event.target.value.toUpperCase() });
    }

    function handleOpcaoPesquisaChange(event) {
        setOpcaoPesquisa(event.target.value);
    }

    function handleSubmit(event) {
        event.preventDefault();
    }

    function handleFind() {
        if (!item.descricao) {
            return;
        }

        axios.get(`${API_BASE_URL.ITEM}/pesquisar_itens`, {
            params: {
                descricao: item.descricao,
                opcaoPesquisa: opcaoPesquisa
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(result => {
                setItens(result.data);
            }).catch(error => {
                console.error('Erro ao buscar itens:', error);
            });
    }

    function handleClear() {
        setItem({ grupo: '', classe: '', codigo: '', descricao: '', situacao: '' });
        setItens([]);
    }
    return (
        <div className='full-screen-div'>
            <Navbar />
            <div className="container mt-4 pt-5">
                <ResponsiveShadow class="container mt-4 mb-4">
                    <main role="main" class="col-md-12 ml-sm-auto col-lg-12 px-md-4">
                        <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                            <h3 class="h3">Pesquisa Item</h3>
                        </div>
                        <form>
                            <div className='row g-2'>
                                <div className='col-md-4 input-group-sm'>
                                    <label className='form-label'>Opções de pesquisa:</label>
                                    <div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="opcaoPesquisa" id="inicioTexto" value="inicioTexto" checked={opcaoPesquisa === 'inicioTexto'} onChange={handleOpcaoPesquisaChange} />
                                            <label className="form-check-label" htmlFor="inicioTexto">Início do Texto</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="opcaoPesquisa" id="qualquerParteTexto" value="qualquerParteTexto" checked={opcaoPesquisa === 'qualquerParteTexto'} onChange={handleOpcaoPesquisaChange} />
                                            <label className="form-check-label" htmlFor="qualquerParteTexto">Em qualquer parte do Texto</label>
                                        </div>
                                    </div>
                                </div>
                                <div class='col-md-8 input-group-sm'>
                                    <label className='form-label'>Descrição:</label>
                                    <input onChange={handleChange} value={item.descricao} name='descricao' type='text' className='form-control'></input>
                                </div>
                                <div class='col-md-2 input-group'>
                                    <input onClick={() => handleFind(item.descricao)} type='button' value='Pesquisar' className='btn btn-success btn-sm'></input>
                                    <input onClick={() => handleClear()} type='button' value='Limpar' className='btn btn-secondary btn-sm mx-1'></input>
                                </div>
                            </div>
                        </form >
                        <br />
                        <div class="table-responsive-sm">
                            <table className="table  table-striped table-sm">
                                <thead className="table-secondary">
                                    <tr>
                                        <th scope="GRUPO">Grupo</th>
                                        <th scope="CLASSE">Classe</th>
                                        <th scope="CODIGO">Código</th>
                                        <th scope="DESCRICAO">Descrição</th>
                                        <th scope="SITUACAO">Situação</th>
                                        <th scope="ACAO">Ação</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {itens.map(i => (
                                        <tr key={i.codigo}>
                                            <td>{i.grupo}</td>
                                            <td>{i.classe}</td>
                                            <td>{i.codigo}</td>
                                            <td>{i.descricao}</td>
                                            <td>{i.situacao}</td>
                                            <td>
                                                <button onClick={() => setItem(i)} className='btn btn-primary btn-sm'>Adicionar</button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </main>
                </ResponsiveShadow>
            </div>
        </div>

    );
}

export default Item;
