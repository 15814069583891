import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import '../css/Navbar.css';

function Navbar() {
    const [user, setUser] = useState(null);
    const [appCliente, setAppCliente] = useState(null);
    const [usuarioBind, setUsuarioBind] = useState(null);
    const navigate = useNavigate();
    const location = useLocation(); // Obtenha a localização atual

    const handleLogout = () => {
        localStorage.clear();
        navigate('/');
    };

    useEffect(() => {
        if (localStorage.getItem('token') === null) {
            navigate('/');
        }
        const userData = localStorage.getItem('user');
        if (userData) {
            setUser(JSON.parse(userData));
        } else {
            navigate('/');
        }
        const appClienteData = localStorage.getItem('appCliente');
        if (appClienteData) {
            setAppCliente(JSON.parse(appClienteData));
        }
        const usuarioBindData = localStorage.getItem('usuarioBind');
        if (usuarioBindData) {
            setUsuarioBind(JSON.parse(usuarioBindData));
        }
    }, [navigate]);

    if (!user) {
        return <div>Loading...</div>;
    }

    // Função para definir a classe "active" no link ativo
    const isActive = (path) => location.pathname === path ? 'nav-link active px-2' : 'nav-link px-2';

    return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
            <div className="container-fluid">
                <a>
                    <img
                        src="/assets/logo.png"
                        alt="Logo"
                        width="100"
                        height="50"
                        className="d-inline-block align-top me-4 rounded-3"
                    />
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <a className={isActive('/home')} href="/home">Home</a>
                        </li>
                        <li className="nav-item dropdown px-2">
                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Administração
                            </a>
                            <ul className="dropdown-menu dropdown-menu-dark bg-dark border border-secondary" aria-labelledby="navbarDropdownMenuLink">
                                {usuarioBind.tipo === 'ADMIN' && user.role === 'ADMIN' && (
                                    <li className="nav-item">
                                        <a className={isActive('/clientes')} href="/clientes">Clientes / Entidades</a>
                                    </li>
                                )}
                                {usuarioBind.tipo === 'ADMIN' && user.role === 'ADMIN' && (
                                    <li className="nav-item">
                                        <a className={isActive('/usuarios')} href="/usuarios">Usuários</a>
                                    </li>)}
                                <li>
                                    <a className={isActive('/requisitantes')} href="/requisitantes">Requisitantes</a>
                                </li>
                                {usuarioBind.tipo === 'ADMIN' && user.role === 'ADMIN' && (
                                    <li>
                                        <hr className="dropdown-divider bg-white border border-secondary" />
                                    </li>)}
                                {usuarioBind.tipo === 'ADMIN' && user.role === 'ADMIN' && (
                                    <li>
                                        <a className={isActive('/upload_template_default')} href="/upload_template_default">Templates dos Documentos</a>
                                    </li>)}
                                {usuarioBind.tipo === 'ADMIN' && user.role === 'ADMIN' && (
                                    <li>
                                        <a className={isActive('/upload_imagem')} href="/upload_imagem">Imagens dos Timbrados</a>
                                    </li>)}

                            </ul>
                        </li>

                        <li className="nav-item dropdown px-2">
                            <a className="nav-link dropdown-toggle" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Demandas
                            </a>
                            <ul className="dropdown-menu dropdown-menu-dark bg-dark border border-secondary" aria-labelledby="navbarDropdownMenuLink">
                                <li><a className={isActive('/demandas_catmat')} href="/demandas_catmat">CatMat</a></li>
                                <li><a className={isActive('/demandas_catser')} href="/demandas_catser">CatSer</a></li>
                                <li>
                                    <hr className="dropdown-divider bg-white border border-secondary" />
                                </li>
                                <li><a className={isActive('/demandas_efisco_pe')} href="/demandas_efisco_pe">E-Fisco PE</a></li>
                            </ul>
                        </li>
                        {/* <li className="nav-item">
                            <a className={isActive('/relatorios')} href="/relatorios">Relatórios</a>
                        </li> */}
                    </ul>
                    <ul className="navbar-nav ms-auto custom-align">
                        <div className="user-info">
                            {user.nome && <div className="nav-link compact" style={{ fontSize: '0.8em' }}>{user.nome}</div>}
                            {appCliente.nome && <div className="nav-link compact" style={{ fontSize: '0.7em' }}>{appCliente.nome}</div>}
                        </div>
                        <li className="nav-item dropdown compact">
                            <a className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                {/* Ícone do usuário */}
                            </a>
                            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-dark bg-dark border border-secondary" aria-labelledby="navbarDropdown">
                                <li><a className="nav-link px-2" href="/seleciona_entidade">Trocar Entidade</a></li>
                                <li>
                                    <hr className="dropdown-divider bg-white border border-secondary" />
                                </li>
                                <li><a className="nav-link px-2" onClick={() => handleLogout()} >Sair</a></li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;
