import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_BASE_URL } from './ApiConfig';
import Navbar from './components/Navbar';
import Pagination from './components/Pagination';
import ConfirmDeleteModal from './components/ConfirmDeleteModal';
import SuccessModal from './components/SuccessModal';
import ResponsiveShadow from './components/ResponsiveShadow';

const UploadTemplateDefault = () => {
    const [file, setFile] = useState(null);
    const [nome, setNome] = useState('');
    const [orientacao, setOrientacao] = useState('');
    const [templates, setTemplates] = useState([]);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [templateToDelete, setTemplateToDelete] = useState(null);
    const navigate = useNavigate();
    const token = localStorage.getItem('token');
    const fileInputRef = useRef(null);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [startIndex, setStartIndex] = useState(0);

    useEffect(() => {
        if (!token) {
            navigate('/');
        } else {
            fetchTemplates();
        }
    }, [navigate, token]);

    useEffect(() => {
        setStartIndex(0);
    }, [rowsPerPage]);

    const fetchTemplates = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL.TEMPLATE_DEFAULT}/list`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setTemplates(response.data);
        } catch (error) {
            console.error('Erro ao buscar os templates:', error);
        }
    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile && selectedFile.type !== "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
            setSuccessMessage("Por favor, selecione um arquivo .docx");
            e.target.value = "";
            setFile(null);
            setShowSuccessModal(true);
        } else {
            setFile(selectedFile);
        }
    };

    const handleNomeChange = (e) => setNome(e.target.value);
    const handleOrientacaoChange = (e) => setOrientacao(e.target.value);

    const handleUpload = async (e) => {
        e.preventDefault();

        if (!file || !nome || !orientacao) {
            setSuccessMessage('Por favor, forneça um nome, forneça uma orientação e selecione um arquivo.');
            setShowSuccessModal(true);
            return;
        }

        const formData = new FormData();
        formData.append('file', file);
        formData.append('nome', nome);
        formData.append('orientacao', orientacao);

        try {
            const response = await axios.post(`${API_BASE_URL.TEMPLATE_DEFAULT}/save`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`
                },
            });
            setSuccessMessage("Template salvo com sucesso!");
            setNome('');
            setOrientacao('');
            setFile(null);
            if (fileInputRef.current) {
                fileInputRef.current.value = "";
            }
            fetchTemplates();
            setShowSuccessModal(true);
        } catch (error) {
            console.error('Erro ao enviar o template:', error);
            setSuccessMessage('Erro ao enviar o template.');
            setShowSuccessModal(true);
        }
    };

    const handleDeleteClick = (template) => {
        setTemplateToDelete(template);
        setShowDeleteModal(true);
    };

    const handleDeleteConfirm = async () => {
        if (templateToDelete) {
            try {
                await axios.delete(`${API_BASE_URL.TEMPLATE_DEFAULT}/delete/${templateToDelete.id}`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                setSuccessMessage('Template excluído com sucesso.');
                fetchTemplates();
                setShowDeleteModal(false);
                setShowSuccessModal(true);
            } catch (error) {
                console.error('Erro ao excluir o template:', error);
                setSuccessMessage('Erro ao excluir o template.');
                setShowSuccessModal(true);
            }
        }
    };

    const endIndex = startIndex + rowsPerPage;
    const selectedDemandas = templates.slice(startIndex, endIndex);
    const totalItems = templates.length;

    return (
        <div className='full-screen-div'>
            <Navbar />
            <div className="container mt-4 pt-5">
                <ResponsiveShadow className="container mt-4 mb-4">
                    <main role="main" className="col-md-12 ml-sm-auto col-lg-12 px-md-4">
                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                            <h3 className="h3">Envio de Template</h3>
                        </div>
                        <div className="col-md-6 ml-sm-auto">
                            <form onSubmit={handleUpload}>
                                <div className='col-md-12 input-group-sm'>
                                    <label className='form-label'>Nome do Template</label>
                                    <select onChange={handleNomeChange} value={nome} name='nome' className='form-select'>
                                        <option value="">Selecione...</option>
                                        <option value="DEMANDA E-FISCO">DEMANDA E-FISCO</option>
                                        <option value="DEMANDA CATMAT">DEMANDA CATMAT</option>
                                        <option value="DEMANDA CATSER">DEMANDA CATSER</option>
                                        <option value="DFD E-FISCO">DFD E-FISCO</option>
                                        <option value="DFD CATMAT">DFD CATMAT</option>
                                        <option value="DFD CATSER">DFD CATSER</option>
                                    </select>
                                </div>
                                <div className='col-md-12 input-group-sm'>
                                    <label className='form-label'>Orientação do Template</label>
                                    <select onChange={handleOrientacaoChange} value={orientacao} name='orientacao' className='form-select'>
                                        <option value="">Selecione...</option>
                                        <option value="P">PAISAGEM</option>
                                        <option value="R">RETRATO</option>
                                    </select>
                                </div>
                                <div className='col-md-12 mt-1 input-group-sm'>
                                    <label className='form-label'>Selecione o Arquivo</label>
                                    <input
                                        className='form-control'
                                        type="file"
                                        onChange={handleFileChange}
                                        name="file"
                                        ref={fileInputRef}
                                        accept=".docx"
                                    />
                                </div>
                                <div className="form-group mb-3 mt-2">
                                    <Link className="btn btn-secondary btn-sm me-1" to="/home">
                                        <i className="bi bi-house" />
                                    </Link>
                                    <button className='btn btn-success btn-sm mx-1' type="submit">Enviar</button>
                                </div>
                            </form>
                        </div>

                        <div className="mt-4">
                            <h4 className="h4">Lista de Templates</h4>
                            <table className="table table-striped table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th>Nome do Template</th>
                                        <th>Orientação</th>
                                        <th>Ações</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedDemandas.length > 0 ? (
                                        selectedDemandas.map((template, index) => (
                                            <tr key={index}>
                                                <td>{template.nome}</td>
                                                <td>{template.orientacao === 'P' ? 'PAISAGEM' : 'RETRATO'}</td>
                                                <td>
                                                    <button
                                                        className="btn btn-danger btn-sm"
                                                        onClick={() => handleDeleteClick(template)}
                                                    >
                                                        <i className="bi bi-trash3 me-1" /> Excluir
                                                    </button>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="3" className="text-center">Nenhum template encontrado.</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                            <Pagination
                                totalItems={totalItems}
                                rowsPerPage={rowsPerPage}
                                startIndex={startIndex}
                                onRowsPerPageChange={(e) => setRowsPerPage(Number(e.target.value))}
                                onFirstItems={() => setStartIndex(0)}
                                onPreviousItems={() => setStartIndex(Math.max(startIndex - rowsPerPage, 0))}
                                onNextItems={() => setStartIndex(Math.min(startIndex + rowsPerPage, totalItems - rowsPerPage))}
                                onLastItems={() => setStartIndex(Math.max(totalItems - rowsPerPage, 0))}
                            />
                        </div>

                        <SuccessModal
                            show={showSuccessModal}
                            onClose={() => setShowSuccessModal(false)}
                            message={successMessage}
                        />

                        <ConfirmDeleteModal
                            show={showDeleteModal}
                            onClose={() => setShowDeleteModal(false)}
                            onConfirm={handleDeleteConfirm}
                        />
                    </main>
                </ResponsiveShadow>
            </div>
        </div>
    );
};

export default UploadTemplateDefault;
