import { useEffect, useState } from 'react';
import axios from 'axios';
import Navbar from './components/Navbar';
import { API_BASE_URL } from './ApiConfig';
import { Link, useNavigate } from 'react-router-dom';
import LoadingModal from './components/LoadingModal';
import ErrorModal from './components/ErrorModal';
import Pagination from './components/Pagination';
import ResponsiveShadow from './components/ResponsiveShadow';

function Usuarios() {
    const [Usuarios, setUsuarios] = useState([]);
    const [atualizar, setAtualizar] = useState([]);
    const [modalMessage, setModalMessage] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const token = localStorage.getItem('token');
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [startIndex, setStartIndex] = useState(0);
    const totalItems = Usuarios.length;

    // Efeito para buscar os usuários da API
    useEffect(() => {
        setIsLoading(true);
        axios.get(`${API_BASE_URL.USUARIO}/all`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(result => {
            setUsuarios(result.data);
        }).catch(error => {
            console.error('Erro ao obter usuários:', error);
            navigate('/');
        }).finally(() => {
            setIsLoading(false);
        });
    }, [atualizar]);

    const selectedUsuarios = Usuarios.slice(startIndex, startIndex + rowsPerPage);
    useEffect(() => {
        setStartIndex(0);
    }, [rowsPerPage]);

    // Função de exclusão de usuário
    function handleDelete(cpf) {
        const loggedUser = JSON.parse(localStorage.getItem('user')); // Usuário logado
        const loggedUserCpf = loggedUser?.cpf; // Obtenha o CPF do usuário logado

        if (cpf === loggedUserCpf) {
            setModalMessage("Você não pode excluir a si mesmo.");
            setShowModal(true);
            return;
        }

        axios.get(`${API_BASE_URL.USUARIO_BIND}/exists_cpf/` + cpf, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(result => {
            if (result.data.exists) {
                setModalMessage("Este usuário possui vínculos e não pode ser excluído. Exclua os vínculos antes de tentar novamente.");
                setShowModal(true);
            } else {
                axios.delete(`${API_BASE_URL.USUARIO}/delete/` + cpf, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }).then(result => {
                    setAtualizar(result);
                }).catch(error => {
                    console.error('Erro ao excluir usuário:', error);
                });
            }
        }).catch(error => {
            console.error('Erro ao verificar vínculos:', error);
        });
    }

    return (
        <div className='full-screen-div'>
            <Navbar />
            <div className="container mt-4 pt-5">
                <ResponsiveShadow className="container mt-4 mb-4">
                    <main role="main" className="col-md-12 ml-sm-auto col-lg-12 px-md-4 mb-3">
                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                            <h3 className="h3">Cadastro de Usuários</h3>
                        </div>
                        <div className="d-flex justify-content-start mb-2">
                            <Link className="btn btn-secondary btn-sm me-2" to="/home">
                                <i className="bi bi-house" /></Link>
                            <Link to="/usuario" className='btn btn-success btn-sm'>
                                <i className="bi bi-plus-lg" /> Novo
                            </Link>
                        </div>

                        <LoadingModal loading={isLoading} />

                        {!isLoading && (
                            <><div className="table-responsive-sm">
                                <table className="table table-hover table-striped table-sm">
                                    <thead className="table-secondary">
                                        <tr>
                                            <th scope="col">CPF</th>
                                            <th scope="col">Nome</th>
                                            <th scope="col">Login</th>
                                            <th scope="col">Situação</th>
                                            <th scope="col" className="text-center px-2">Ações</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {selectedUsuarios.map(usu => (
                                            <tr key={usu.cpf}>
                                                <td>{usu.cpf}</td>
                                                <td>{usu.nome}</td>
                                                <td>{usu.login}</td>
                                                <td>{usu.situacao}</td>
                                                <td className="text-center px-2">
                                                    <Link to="/usuario" state={{ usuario: usu }} className='btn btn-primary btn-sm mx-1 my-1'>
                                                        <i className="bi bi-pencil-fill" /> Alterar
                                                    </Link>
                                                    <Link to="/usuario_vinculos" state={{ usuario: usu }} className='btn btn-success btn-sm mx-1 my-1'>
                                                        <i className="bi bi-link-45deg" /> Vincular
                                                    </Link>
                                                    <button onClick={() => handleDelete(usu.cpf)} className='btn btn-danger btn-sm mx-1 my-1'>
                                                        <i className="bi bi-trash" /> Excluir
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                                {/* Componente de Paginação */}
                                <Pagination
                                    totalItems={totalItems}
                                    rowsPerPage={rowsPerPage}
                                    startIndex={startIndex}
                                    onRowsPerPageChange={(e) => setRowsPerPage(Number(e.target.value))}
                                    onFirstItems={() => setStartIndex(0)}
                                    onPreviousItems={() => setStartIndex(Math.max(startIndex - rowsPerPage, 0))}
                                    onNextItems={() => setStartIndex(Math.min(startIndex + rowsPerPage, totalItems - rowsPerPage))}
                                    onLastItems={() => setStartIndex(Math.max(totalItems - rowsPerPage, 0))}
                                />
                            </>
                        )}
                    </main>
                </ResponsiveShadow>
            </div>

            <ErrorModal
                show={showModal}
                message={modalMessage}
                onClose={() => setShowModal(false)}
            />
        </div>
    );
}

export default Usuarios;
