import { useEffect, useState } from 'react';
import './css/Cliente.css';
import axios from 'axios';
import Navbar from './components/Navbar';
import { API_BASE_URL } from './ApiConfig';
import { Link, useNavigate } from 'react-router-dom';
import LoadingModal from './components/LoadingModal';  // Componente de loading
import ErrorModal from './components/ErrorModal';  // Componente de erro
import Pagination from './components/Pagination';
import ResponsiveShadow from './components/ResponsiveShadow';

function Clientes() {
    const [Clientes, setClientes] = useState([]);
    const [atualizar, setAtualizar] = useState([]);
    const token = localStorage.getItem('token');
    const [modalMessage, setModalMessage] = useState(''); // Mensagem do modal
    const [showModal, setShowModal] = useState(false); // Controle de visibilidade do modal
    const [isLoading, setIsLoading] = useState(true); // Controle de carregamento
    const navigate = useNavigate();
    const [rowsPerPage, setRowsPerPage] = useState(5); // Quantidade de linhas por página
    const [startIndex, setStartIndex] = useState(0); // Índice inicial dos itens exibidos

    useEffect(() => {
        if (!token) {
            navigate('/');
        }
        setIsLoading(true); // Iniciar o carregamento
        axios.get(`${API_BASE_URL.CLIENTE}/all`, {
            headers: {
                Authorization: `Bearer ${token}` // Incluir token no cabeçalho da requisição
            }
        }).then(result => {
            setClientes(result.data);
        }).catch(error => {
            console.error('Erro ao obter clientes:', error);
        }).finally(() => {
            setIsLoading(false); // Desativa o loading quando terminar
        });
    }, [atualizar]);

    // Início da Paginação
    const endIndex = startIndex + rowsPerPage;
    const selectedClientes = Clientes.slice(startIndex, endIndex);
    const totalItems = Clientes.length;

    useEffect(() => {
        setStartIndex(0);
    }, [rowsPerPage]);

    function handleDelete(c) {
        axios.get(`${API_BASE_URL.USUARIO_BIND}/exists_cnpj/` + c.cnpj, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(result => {
            if (result.data.exists) {
                setModalMessage("Este Cliente/Entidade possui vínculos e não pode ser excluído. Exclua os vínculos antes de tentar novamente.");
                setShowModal(true);
            } else {
                axios.delete(`${API_BASE_URL.CLIENTE}/delete/` + c.cnpj, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }).then(result => {
                    setAtualizar(result);
                });
            }
        }).catch(error => {
            console.error('Erro ao verificar vínculos:', error);
        });
    }

    return (
        <div className='full-screen-div'>
            <Navbar />
            {/* <div className="d-flex" id="wrapper"> */}
            <div className="container mt-4 pt-5">
                <ResponsiveShadow className="container mt-4 mb-4 px-3 py-3">
                    <main role="main" className="col-md-12 ml-sm-auto col-lg-12 px-md-4">
                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                            <h3 className="h3">Cadastro de Clientes</h3>
                        </div>
                        <div className="d-flex justify-content-start mb-2">
                            <Link className="btn btn-secondary btn-sm me-2" to="/home">
                                <i className="bi bi-house" /></Link>
                            <Link to="/cliente" className='btn btn-success btn-sm me-2'>
                                <i className="bi bi-plus-lg" /> Novo
                            </Link>
                        </div>
                        {/* Modal de Carregamento */}
                        <LoadingModal loading={isLoading} />
                        {/* Conteúdo da página só aparece após o carregamento */}
                        {!isLoading && (
                            <>
                                <div className="table-responsive-sm">
                                    <table className="table table-hover table-striped table-sm">
                                        <thead className="table-secondary">
                                            <tr>
                                                <th scope="col" className='px-2 d-none d-md-table-cell'>CNPJ</th>
                                                <th scope="col" className='px-2'>Nome</th>
                                                <th scope="col" className="text-center align-middle px-2">Ações</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {selectedClientes.map(c => (
                                                <tr key={c.cnpj}>
                                                    <td className='px-2 d-none d-md-table-cell'>{c.cnpj}</td>
                                                    <td className='px-2'>{c.nome}</td>
                                                    <td className="text-center px-2">
                                                        <Link to="/cliente" state={{ cliente: c }} className='btn btn-primary btn-sm mx-1 my-1'>
                                                            <i className="bi bi-pencil-fill" /> Alterar
                                                        </Link>
                                                        <button onClick={() => handleDelete(c)} className='btn btn-danger btn-sm mx-1 my-1'>
                                                            <i className="bi bi-trash" /> Excluir
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                        <Pagination
                                            totalItems={totalItems}
                                            rowsPerPage={rowsPerPage}
                                            startIndex={startIndex}
                                            onRowsPerPageChange={(e) => setRowsPerPage(Number(e.target.value))}
                                            onFirstItems={() => setStartIndex(0)}
                                            onPreviousItems={() => setStartIndex(Math.max(startIndex - rowsPerPage, 0))}
                                            onNextItems={() => setStartIndex(Math.min(startIndex + rowsPerPage, totalItems - rowsPerPage))}
                                            onLastItems={() => setStartIndex(Math.max(totalItems - rowsPerPage, 0))}
                                        />
                                </div>
                            </>
                        )}
                    </main>
                </ResponsiveShadow>
            </div>

            {/* Modal de Erro/Vínculos */}
            <ErrorModal
                show={showModal}
                message={modalMessage}
                onClose={() => setShowModal(false)}
            />
        </div>
    );
}

export default Clientes;
