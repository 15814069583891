// Função para remover pontuação do Telefone
export function unformatTelefone(celular) {
    return celular.replace(/[^\d]/g, ''); // Remove todos os caracteres que não são dígitos
}

// Função para formatar Telefone (0000000000 -> (00) 0000-0000)
export function formatTelefone(celular) {
    if (celular.length === 10) {
        return celular.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
    }
    return celular;
}
