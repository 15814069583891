import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import NavbarEmpty from './components/NavbarEmpty';
import './css/PosLogin.css'; // Importe o arquivo CSS
import ResponsiveShadow from './components/ResponsiveShadow';

function SelecionaEntidade() {
    const [selectedOption, setSelectedOption] = useState('');
    const [UsuarioBinds, setUsuarioBinds] = useState([]);
    const [modalMessage, setModalMessage] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        // Carregar os dados do localStorage quando o componente for montado
        const storedOptions = localStorage.getItem('userBind');
        if (storedOptions) {
            try {
                const parsedOptions = JSON.parse(storedOptions);
                // Ordenar as opções pelo nome em ordem alfabética
                parsedOptions.sort((a, b) => a.appCliente.nome.localeCompare(b.appCliente.nome));
                setUsuarioBinds(parsedOptions);
            } catch (error) {
                console.error('Erro ao parsear os dados do localStorage:', error);
            }
        }
        // axios.get(`${API_BASE_URL.CLIENTE}/all`, {
        //     headers: {
        //         Authorization: `Bearer ${token}` // Incluir token no cabeçalho da requisição
        //     }
        // }).then(result => {
        //     setClientes(result.data);
        // })
    }, []);

    const handleAdvance = () => {
        // Encontrar o objeto `appCliente` selecionado
        const selectedUsuarioBind = UsuarioBinds.find(option => option.appCliente.cnpj === selectedOption);

        // Verificar se `selectedClient` existe e tem o campo `cnpj`
        if (selectedUsuarioBind && selectedUsuarioBind.appCliente.cnpj.length === 14) {
            // Salvar o objeto `appCliente` como string JSON
            localStorage.setItem('appCliente', JSON.stringify(selectedUsuarioBind.appCliente));
            localStorage.setItem('cnpj', selectedUsuarioBind.appCliente.cnpj);
            localStorage.setItem('usuarioBind', JSON.stringify(selectedUsuarioBind));
            navigate('/home');
        } else {
            setModalMessage('Por favor, selecione uma opção válida.');
            const modal = new bootstrap.Modal(document.getElementById('errorModal'));
            modal.show();
        }
    };

    const handleLogout = () => {
        localStorage.clear();
        navigate('/');
    };

    return (
        <div className='full-screen-div'>
            <NavbarEmpty />
            <div className="d-flex flex-column justify-content-center align-items-center" style={{ height: '100vh' }}>
                <ResponsiveShadow className="w-100 px-3 p-4 rounded d-flex flex-column align-items-center mx-4 my-4" style={{ maxWidth: '500px' }}>
                    <select
                        className="form-select mb-4 mt-4"
                        value={selectedOption}
                        onChange={(e) => setSelectedOption(e.target.value)}
                    >
                        <option value="">Selecione a Entidade</option>
                        {UsuarioBinds.map((option, index) => (
                            <option key={index} value={option.appCliente.cnpj}>
                                {option.appCliente.nome}
                            </option>
                        ))}
                    </select>
                    <div>
                        <button className="btn btn-primary me-2" onClick={handleAdvance}>Avançar</button>
                        <button className="btn btn-secondary" onClick={handleLogout}>Sair</button>
                    </div>
                </ResponsiveShadow>
            </div>

            {/* Modal */}
            <div className="modal fade" id="errorModal" tabIndex="-1" aria-labelledby="errorModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="errorModalLabel">Aviso</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            {modalMessage}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default SelecionaEntidade;
