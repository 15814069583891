import React from 'react';
import Navbar from './components/Navbar';
import ResponsiveShadow from './components/ResponsiveShadow';

const Home = () => {
  return (
    <div className='full-screen-div'>
      <Navbar />
      <div className="container mt-4 pt-5">
        <ResponsiveShadow className="container mt-4 mb-4 px-3 py-3">
          <div class="row row-cols-1 row-cols-md-3 g-4 mt-1 sm">
            <div class="col mb-3">
              <div class="card h-100">
                {/* <img src="..." class="card-img-top" alt="..." /> */}
                <div class="card-body">
                  <h5 class="card-title">Demandas CATMAT</h5>
                  <p class="card-text">Demandas com base no Catálogo de Materiais do ComprasGov - Governo Federal.</p>
                </div>
                <div className='card-footer bg-transparent'>
                  <a href="/demandas_catmat" class="btn btn-secondary">Ir</a>
                </div>
              </div>
            </div>
            <div class="col mb-3">
              <div class="card h-100">
                {/* <img src="..." class="card-img-top" alt="..." /> */}
                <div class="card-body">
                  <h5 class="card-title">Demandas CATSER</h5>
                  <p class="card-text">Demandas com base no Catálogo de Serviços do ComprasGov - Governo Federal.</p>
                </div>
                <div className='card-footer bg-transparent'>
                  <a href="/demandas_catser" class="btn btn-secondary">Ir</a>
                </div>
              </div>
            </div>
            <div class="col mb-3">
              <div class="card h-100">
                {/* <img src="..." class="card-img-top" alt="..." /> */}
                <div class="card-body">
                  <h5 class="card-title">Demandas E-Fisco/PE</h5>
                  <p class="card-text">Demandas com base no Catálogo de Materiais e Serviços do E-Fisco do Estado de Pernambuco.</p>
                </div>
                <div className='card-footer bg-transparent'>
                  <a href="/demandas_efisco_pe" class="btn btn-secondary">Ir</a>
                </div>
              </div>
            </div>
          </div>
        </ResponsiveShadow>
      </div>
    </div>

  );
}

export default Home;