export function formatDateView(dateString) {
    if (!dateString) {
        return "";
    }
    // Extrai ano, mês e dia usando substring
    const year = dateString.substring(0, 4);
    const month = dateString.substring(5, 7);
    const day = dateString.substring(8, 10);
    // Retorna a data formatada no estilo DD/MM/YYYY
    return `${day}/${month}/${year}`;
}