import React, { useEffect, useState, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './css/Cliente.css';
import axios from 'axios';
import Navbar from './components/Navbar';
import { API_BASE_URL } from './ApiConfig';
import 'bootstrap-icons/font/bootstrap-icons.css';
import LoadingModal from './components/LoadingModal';
import ErrorModal from './components/ErrorModal';
import SuccessModal from './components/SuccessModal';
import ConfirmDeleteModal from './components/ConfirmDeleteModal';
import Pagination from './components/Pagination';
import ResponsiveShadow from './components/ResponsiveShadow';

function CatmatDemandaItens() {
    const [Demanda, setDemanda] = useState({ cnpj: '', dfd: '', dataConclusao: '', requisitante: '', objeto: '', prioridade: '', justificativaPrioridade: '', justificativaDemanda: '', tipo: '', usuario: '' });
    const [DemandaItens, setDemandaItens] = useState([]);
    const [unidades, setUnidades] = useState([]);
    const [itemToDelete, setItemToDelete] = useState(null);
    const [shouldFetch, setShouldFetch] = useState(false);
    const token = localStorage.getItem('token');
    const location = useLocation();
    const modalRef = useRef(null);
    const saveModalRef = useRef(null);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [startIndex, setStartIndex] = useState(0);
    const [modalMessage, setModalMessage] = useState('');
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        if (location.state && location.state.dfd) {
            setDemanda(location.state.dfd);
        }
        if (Demanda.cnpj !== '') {
            axios.get(`${API_BASE_URL.CATMAT_DFD_ITEM}/dfd`, {
                params: {
                    cnpj: Demanda.cnpj,
                    dfd: Demanda.dfd
                },
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(result => {
                const formattedData = result.data.map(item => ({
                    ...item,
                    vlrUnit: formatCurrency(item.vlrUnit),
                }));
                setDemandaItens(formattedData);
                setIsLoading(false);
            }).catch(error => {
                console.error('Erro ao obter listas:', error);
                setIsLoading(false);
            });

            axios.get(`${API_BASE_URL.UNIDADE_MEDIDA}/all`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(result => {
                setUnidades(result.data);
            }).catch(error => {
                console.error('Erro ao obter unidades:', error);
            });
        }

    }, [location.state, Demanda, shouldFetch]);

    useEffect(() => {
        setStartIndex(0);
    }, [rowsPerPage]);


    if (!DemandaItens) {
        return <div>Loading...</div>;
    }
    const endIndex = startIndex + rowsPerPage;
    const selectedDemandaItens = DemandaItens.slice(startIndex, endIndex);
    const totalItems = DemandaItens.length;

    function handleDelete(dfd) {
        setItemToDelete(dfd);
        setShowConfirmDeleteModal(true);
    }

    function handleDeleteConfirm() {
        axios.delete(`${API_BASE_URL.CATMAT_DFD_ITEM}/delete`, {
            params: {
                cnpj: itemToDelete.cnpj,
                dfd: itemToDelete.dfd,
                grupo: itemToDelete.grupo,
                classe: itemToDelete.classe,
                pdm: itemToDelete.pdm,
                item: itemToDelete.item
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(result => {
            setShouldFetch(!shouldFetch);
            setItemToDelete(null);
            setShowConfirmDeleteModal(false);
        })
            .catch(error => {
                console.error('Erro ao excluir o item:', error);
            });
    }

    function formatCurrency(value) {
        if (isNaN(value)) return value;
        return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
    }

    function unformatCurrency(value) {
        return value.replace(/\D/g, '') / 100;
    }

    function calculateTotal(quantidade, vlrUnit) {
        const quantidadeNum = parseFloat(quantidade) || 0;
        const vlrUnitNum = unformatCurrency(vlrUnit) || 0;
        return formatCurrency(quantidadeNum * vlrUnitNum);
    }

    const handleInputChange = (index, field, value) => {
        const updatedDemandaItens = [...DemandaItens];
        if (field === 'vlrUnit') {
            updatedDemandaItens[index][field] = formatCurrency(unformatCurrency(value));
        } else {
            updatedDemandaItens[index][field] = value;
        }
        setDemandaItens(updatedDemandaItens);
    };

    const handleSave = (index) => {
        const demandasItens = DemandaItens[index];
        const itemToSave = {
            ...demandasItens,
            vlrUnit: unformatCurrency(demandasItens.vlrUnit)
        };
        axios.post(`${API_BASE_URL.DFD_ITEM}/save`, itemToSave, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(result => {
            console.log('Item atualizado com sucesso:', result.data);
            const modal = new window.bootstrap.Modal(saveModalRef.current);
            modal.show();
        }).catch(error => {
            console.error('Erro ao atualizar o item:', error);
        });
    };

    const handleSaveAll = () => {
        const itemsToSave = DemandaItens.map(item => ({
            ...item,
            vlrUnit: unformatCurrency(item.vlrUnit),
        }));
        console.log("ITENS: ", itemsToSave);
        axios.post(`${API_BASE_URL.CATMAT_DFD_ITEM}/saveAll`, itemsToSave, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(result => {
            console.log('Itens atualizados com sucesso:', result.data);
            setModalMessage('Itens salvos com sucesso!');
            setShowSuccessModal(true);
        }).catch(error => {
            console.error('Erro ao atualizar os itens:', error);
        });
    };

    return (
        <div className='full-screen-div'>
            <Navbar />
            <div className="container mt-4 pt-5">
                <ResponsiveShadow className="container mt-4 mb-4">
                    <main role="main" className="col-md-12 ml-sm-auto col-lg-12 px-md-4">
                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-1 mb-2 border-bottom">
                            <h3 className="h3">Itens da Demanda CATMAT</h3>
                        </div>

                        {/* Agrupamento dos botões */}
                        <div className="d-flex justify-content-start mb-2">
                            <Link to="/demandas_catmat" state={{ dfd: Demanda }} className='btn btn-secondary btn-sm me-2'>
                                <i className="bi bi-arrow-left" /> Voltar
                            </Link>
                            <Link to="/demanda_catmat_item_find" state={{ dfd: Demanda }} className='btn btn-success btn-sm me-2'>
                                <i className="bi bi-plus-lg" /> Adicionar Item
                            </Link>
                            <button onClick={handleSaveAll} className='btn btn-primary btn-sm'>
                                <i className="bi bi-save" /> Salvar Todos
                            </button>
                        </div>
                        <LoadingModal loading={isLoading} />
                        {!isLoading && (
                            <>
                                <div className="table-responsive">
                                    <table className="table table-hover table-sm">
                                        <thead className="table-secondary">
                                            <tr>
                                                <th scope="col" className="text-center align-middle">Grupo</th>
                                                <th scope="col" className="text-center align-middle">Classe</th>
                                                <th scope="col" className="text-center align-middle">Código</th>
                                                <th scope="col" className="text-center align-middle">Descrição</th>
                                                <th scope="col" className="text-center align-middle">Unidade</th>
                                                <th scope="col" className="text-center align-middle">Quantidade</th>
                                                <th scope="col" className="text-center align-middle">Valor Unitário</th>
                                                <th scope="col" className="text-center align-middle">Valor Total</th>
                                                <th scope="col" className="text-center align-middle">Ações</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {selectedDemandaItens.map((c, index) => (
                                                <tr key={`${c.cnpj}-${c.dfd}-${c.grupo}-${c.classe}-${c.pdm}-${c.item}`}>
                                                    <td>{c.grupo}</td>
                                                    <td>{c.classe}</td>
                                                    <td>{c.item}</td>
                                                    <td>{c.catmatItem.descricao}</td>
                                                    <td>
                                                        <select
                                                            name="unidade"
                                                            value={c.unidade}
                                                            onChange={(e) => handleInputChange(index, 'unidade', e.target.value)}
                                                            className="form-select form-select-sm"
                                                        >   <option value="">Selecione...</option>
                                                            {unidades.map(unidade => (
                                                                <option key={unidade.id} value={unidade.descricao}>{unidade.descricao}</option>
                                                            ))}
                                                        </select>
                                                    </td>
                                                    <td className="text-end">
                                                        <input
                                                            type="number"
                                                            name="quantidade"
                                                            value={c.quantidade}
                                                            onChange={(e) => handleInputChange(index, 'quantidade', e.target.value)}
                                                            className="form-control form-control-sm text-end"
                                                        />
                                                    </td>
                                                    <td className="text-end">
                                                        <input
                                                            type="text"
                                                            name="vlrUnit"
                                                            value={c.vlrUnit}
                                                            onChange={(e) => handleInputChange(index, 'vlrUnit', e.target.value)}
                                                            className="form-control form-control-sm text-end"
                                                        />
                                                    </td>
                                                    <td className="text-end">
                                                        {calculateTotal(c.quantidade, c.vlrUnit)}
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="d-flex justify-content-around">
                                                            <button onClick={() => handleDelete(c)} className="btn btn-danger btn-sm" title="Excluir">
                                                                <i className="bi bi-trash"></i>Excluir
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                    <Pagination
                                        totalItems={totalItems}
                                        rowsPerPage={rowsPerPage}
                                        startIndex={startIndex}
                                        onRowsPerPageChange={(e) => setRowsPerPage(Number(e.target.value))}
                                        onFirstItems={() => setStartIndex(0)}
                                        onPreviousItems={() => setStartIndex(Math.max(startIndex - rowsPerPage, 0))}
                                        onNextItems={() => setStartIndex(Math.min(startIndex + rowsPerPage, totalItems - rowsPerPage))}
                                        onLastItems={() => setStartIndex(Math.max(totalItems - rowsPerPage, 0))}
                                    />
                                </div>
                            </>
                        )}
                    </main>
                </ResponsiveShadow>
            </div>

            <ConfirmDeleteModal
                show={showConfirmDeleteModal}
                onClose={() => setShowConfirmDeleteModal(false)} // Fechar modal ao cancelar
                onConfirm={handleDeleteConfirm} // Executa exclusão e fecha modal
            />

            <SuccessModal
                show={showSuccessModal}
                message={modalMessage}
                onClose={() => setShowSuccessModal(false)}
            />
        </div>
    );
}

export default CatmatDemandaItens;
