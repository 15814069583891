// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.compact {
    text-align: right;
    margin: 0;
    padding: 0;
}
/* Alinha os itens à direita em telas maiores */
.custom-align {
    margin-left: auto;
}

/* Para telas pequenas, o conteúdo deve ser alinhado à esquerda */
@media (max-width: 576px) {
    .navbar-nav {
        justify-content: flex-start; /* Alinha todos os itens à esquerda */
    }

    .user-info {
        text-align: left; /* Garante que o conteúdo vai para a esquerda */
    }
}

/* .nav-link.active {
    font-weight: bold;
} */`, "",{"version":3,"sources":["webpack://./src/service/css/Navbar.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,SAAS;IACT,UAAU;AACd;AACA,+CAA+C;AAC/C;IACI,iBAAiB;AACrB;;AAEA,iEAAiE;AACjE;IACI;QACI,2BAA2B,EAAE,qCAAqC;IACtE;;IAEA;QACI,gBAAgB,EAAE,+CAA+C;IACrE;AACJ;;AAEA;;GAEG","sourcesContent":[".compact {\r\n    text-align: right;\r\n    margin: 0;\r\n    padding: 0;\r\n}\r\n/* Alinha os itens à direita em telas maiores */\r\n.custom-align {\r\n    margin-left: auto;\r\n}\r\n\r\n/* Para telas pequenas, o conteúdo deve ser alinhado à esquerda */\r\n@media (max-width: 576px) {\r\n    .navbar-nav {\r\n        justify-content: flex-start; /* Alinha todos os itens à esquerda */\r\n    }\r\n\r\n    .user-info {\r\n        text-align: left; /* Garante que o conteúdo vai para a esquerda */\r\n    }\r\n}\r\n\r\n/* .nav-link.active {\r\n    font-weight: bold;\r\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
