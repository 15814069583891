// Função para remover pontuação do CEP
export function unformatCEP(cep) {
    return cep.replace(/[^\d]/g, ''); // Remove todos os caracteres que não são dígitos
}

// Função para formatar CEP (000000000 -> 50.000-000)
export function formatCEP(cep) {
    if (cep.length === 8) {
        return cep.replace(/(\d{2})(\d{3})(\d{3})/, '$1.$2-$3');
    }
    return cep;
}
