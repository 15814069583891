import React from 'react';
import InputMask from 'react-input-mask';

function CelularMaskInput({ value, onChange, ...rest }) {
    return (
        <InputMask
            mask="(99) 99999-9999"
            value={value}
            onChange={onChange}
        >
            {(inputProps) => <input {...inputProps} {...rest} className="form-control" />}
        </InputMask>
    );
}

export default CelularMaskInput;
