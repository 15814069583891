import React, { useState, useEffect, useRef } from 'react';
import './css/Item.css';
import axios from 'axios';
import Navbar from './components/Navbar';
import { API_BASE_URL } from './ApiConfig';
import { Link, useLocation } from 'react-router-dom';
import Pagination from './components/Pagination';
import ResponsiveShadow from './components/ResponsiveShadow';

function DemandaItemFind() {
    const [Dfd, setDfd] = useState({ cnpj: '', num: '', dataConclusao: '', requisitante: '', objeto: '', prioridade: '', justificativaPrioridade: '', justificativaDemanda: '', tipo: '', usuario: '' });
    const [Item, setItem] = useState({ grupo: '', classe: '', codigo: '', descricao: '', situacao: '' });
    const location = useLocation();
    const [Itens, setItens] = useState([]);
    const [opcaoPesquisa, setOpcaoPesquisa] = useState('inicioTexto');
    const token = localStorage.getItem('token');
    const modalRef = useRef(null); // Ref para o modal
    const [rowsPerPage, setRowsPerPage] = useState(5); // Quantidade de linhas por página
    const [startIndex, setStartIndex] = useState(0); // Índice inicial dos itens exibidos


    function handleChange(event) {
        setItem({ ...Item, [event.target.name]: event.target.value.toUpperCase() });
    }

    function handleOpcaoPesquisaChange(event) {
        setOpcaoPesquisa(event.target.value);
    }

    function handleSubmit(event) {
        event.preventDefault();
    }

    function handleAdd(item) {
        const newDfdItem = {
            cnpj: Dfd.cnpj,
            numDfd: Dfd.num,
            grupo: item.grupo,
            classe: item.classe,
            codigo: item.codigo,
            unidade: '', // Defina isso conforme necessário
            quantidade: '', // Defina isso conforme necessário
            vlr_unit: '' // Defina isso conforme necessário
        };

        axios.post(`${API_BASE_URL.DFD_ITEM}/save`, newDfdItem, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(response => {
                console.log('Item salvo com sucesso:', response.data);
                // Mostrar o modal de confirmação
                const modalElement = modalRef.current;
                const bootstrapModal = new window.bootstrap.Modal(modalElement);
                bootstrapModal.show();
            })
            .catch(error => {
                console.error('Erro ao salvar o item:', error);
            });
    }

    useEffect(() => {
        if (location.state && location.state.dfd) {
            setDfd(location.state.dfd);
        }
    }, [location.state]);

    if (!Itens) {
        return <div>Loading...</div>;
    }
    const endIndex = startIndex + rowsPerPage;
    const selectedItens = Itens.slice(startIndex, endIndex);
    const totalItems = Itens.length;
    useEffect(() => {
        setStartIndex(0);
    }, [rowsPerPage]);

    function handleFind() {
        if (!Item.descricao) {
            return;
        }

        axios.get(`${API_BASE_URL.ITEM}/pesquisar_itens`, {
            params: {
                descricao: Item.descricao,
                opcaoPesquisa: opcaoPesquisa
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(result => {
                setItens(result.data);
            })
            .catch(error => {
                console.error('Erro ao buscar itens:', error);
            });
    }

    function handleClear() {
        setItem({ grupo: '', classe: '', codigo: '', descricao: '', situacao: '' });
        setItens([]);
    }

    return (
        <div className='full-screen-div'>
            <Navbar />
            <div className="container mt-4 pt-5">
                <ResponsiveShadow className="container mt-4 mb-4">
                    <main role="main" className="col-md-12 ml-sm-auto col-lg-12 px-md-4">
                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                            <h3 className="h3">Pesquisa Item E-fisco/PE</h3>
                        </div>
                        <form>
                            <div className='row g-2'>
                                <div className='col-md-4 input-group-sm'>
                                    <label className='form-label'>Opções de pesquisa:</label>
                                    <div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="opcaoPesquisa" id="inicioTexto" value="inicioTexto" checked={opcaoPesquisa === 'inicioTexto'} onChange={handleOpcaoPesquisaChange} />
                                            <label className="form-check-label" htmlFor="inicioTexto">Início do Texto</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="opcaoPesquisa" id="qualquerParteTexto" value="qualquerParteTexto" checked={opcaoPesquisa === 'qualquerParteTexto'} onChange={handleOpcaoPesquisaChange} />
                                            <label className="form-check-label" htmlFor="qualquerParteTexto">Em qualquer parte do Texto</label>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-8 input-group-sm'>
                                    <label className='form-label'>Descrição:</label>
                                    <input onChange={handleChange} value={Item.descricao} onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()} name='descricao' type='text' className='form-control'></input>
                                </div>
                                <div className='col-md-2 input-group'>
                                    <Link to="/demanda_itens_efisco_pe" state={{ dfd: Dfd }} className='btn btn-secondary btn-sm mx-1'><i className="bi bi-arrow-left" /> Voltar</Link>
                                    <input onClick={() => handleFind(Item.descricao)} type='button' value='Pesquisar' className='btn btn-success btn-sm'></input>
                                    <input onClick={() => handleClear()} type='button' value='Limpar' className='btn btn-secondary btn-sm mx-1'></input>
                                </div>
                            </div>
                        </form>
                        <br />
                        <div className="table-responsive-sm">
                            <table className="table table-hover table-striped table-sm">
                                <thead className="table-secondary">
                                    <tr>
                                        <th scope="GRUPO">Grupo</th>
                                        <th scope="CLASSE">Classe</th>
                                        <th scope="CODIGO">Código</th>
                                        <th scope="DESCRICAO">Descrição</th>
                                        <th scope="SITUACAO">Situação</th>
                                        <th scope="ACAO">Ação</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedItens.map(i => (
                                        <tr key={`${i.grupo}-${i.classe}-${i.codigo}`}>
                                            <td>{i.grupo}</td>
                                            <td>{i.classe}</td>
                                            <td>{i.codigo}</td>
                                            <td>{i.descricao}</td>
                                            <td>{i.situacao}</td>
                                            <td>
                                                <button onClick={() => handleAdd(i)} className='btn btn-primary btn-sm'>Adicionar</button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <Pagination
                                totalItems={totalItems}
                                rowsPerPage={rowsPerPage}
                                startIndex={startIndex}
                                onRowsPerPageChange={(e) => setRowsPerPage(Number(e.target.value))}
                                onFirstItems={() => setStartIndex(0)}
                                onPreviousItems={() => setStartIndex(Math.max(startIndex - rowsPerPage, 0))}
                                onNextItems={() => setStartIndex(Math.min(startIndex + rowsPerPage, totalItems - rowsPerPage))}
                                onLastItems={() => setStartIndex(Math.max(totalItems - rowsPerPage, 0))}
                            />
                        </div>
                    </main>
                </ResponsiveShadow>
            </div>

            {/* Modal de Confirmação */}
            <div className="modal fade" id="addConfirmationModal" tabIndex="-1" aria-labelledby="addConfirmationModalLabel" aria-hidden="true" ref={modalRef}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addConfirmationModalLabel">Item Adicionado</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            O item foi adicionado com sucesso!
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">OK</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DemandaItemFind;
