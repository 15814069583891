import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './css/Cliente.css';
import axios from 'axios';
import Navbar from './components/Navbar';
import { API_BASE_URL } from './ApiConfig';
import ResponsiveShadow from './components/ResponsiveShadow';

function Lista() {
    const [MatLista, setMatLista] = useState({ cnpj: '', id: '', data: '', descricao: '', tipo: '' });
    const token = localStorage.getItem('token');
    const location = useLocation();
    const descricaoRef = useRef(null);

    useEffect(() => {
        const storedCnpj = localStorage.getItem('cnpj');
        if (location.state && location.state.matLista) {
            setMatLista({
                ...location.state.matLista,
                cnpj: storedCnpj || location.state.matLista.cnpj,
            });
        } else {
            setMatLista({
                ...MatLista,
                cnpj: storedCnpj || '',
            });
        }
    }, [location.state]);

    function formatDateForInput(dateString) {
        if (dateString) {
            const date = new Date(dateString);
            const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
            return formattedDate;
        }
        return '';
    }

    function handleChange(event) {
        setMatLista({ ...MatLista, [event.target.name]: event.target.value.toUpperCase() });
    }

    function handleDescricaoInput(event) {
        const cursorPosition = event.target.selectionStart;
        const newValue = event.target.value.toUpperCase();

        setMatLista(prevState => ({
            ...prevState,
            descricao: newValue
        }));

        setTimeout(() => {
            descricaoRef.current.selectionStart = cursorPosition;
            descricaoRef.current.selectionEnd = cursorPosition;
        }, 0);
    }

    function handleSubmit(event) {
        event.preventDefault();

        const utcDate = new Date(MatLista.data);
        const year = utcDate.getUTCFullYear();
        const month = (utcDate.getUTCMonth() + 1).toString().padStart(2, '0');
        const day = (utcDate.getUTCDate() + 1).toString().padStart(2, '0');
        const formattedUTCDate = `${year}-${month}-${day}`;

        axios.post(`${API_BASE_URL.LISTA}/save`, { ...MatLista, data: formattedUTCDate }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(result => {
            window.location.href = '/listas';
        });
    }

    return (
        <div className='full-screen-div'>
            <Navbar />
            <div className="container mt-4 pt-5">
                <ResponsiveShadow className="container mt-4 mb-4">
                    <main role="main" className="col-md-12 ml-sm-auto col-lg-12 px-md-4">
                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                            <h3 className="h3">Cadastro de Listas de Materiais ou Serviços</h3>
                        </div>
                        <div className="col-md-6 ml-sm-auto">
                            <form onSubmit={handleSubmit}>
                                <div className='row g-3'>
                                    <div className={`col-md-${MatLista.id ? '5' : '6'} input-group-sm`}>
                                        <label className='form-label'>CNPJ</label>
                                        <input onChange={handleChange} value={MatLista.cnpj} name='cnpj' type='text' className='form-control' disabled />
                                    </div>
                                    {/* {MatLista.id && (
                                    <div className='col-md-2 input-group-sm'>
                                        <label className='form-label'>Id</label>
                                        <input onChange={handleChange} value={MatLista.id} name='id' type='text' className='form-control' disabled />
                                    </div>
                                )} */}
                                    <div className={`col-md-${MatLista.id ? '5' : '6'} input-group-sm`}>
                                        <label className='form-label'>Data</label>
                                        <input onChange={handleChange} defaultValue={formatDateForInput(MatLista.data)} name='data' type='date' id='data' className='form-control' />
                                    </div>
                                    <div className='col-md-12 input-group-sm'>
                                        <label className='form-label'>Descrição</label>
                                        <input ref={descricaoRef} onInput={handleDescricaoInput} value={MatLista.descricao} name='descricao' type='text' className='form-control' />
                                    </div>
                                    <div className='col-md-4 input-group-sm'>
                                        <label className='form-label'>Tipo</label>
                                        <select className='form-select' value={MatLista.tipo} onChange={handleChange} name='tipo'>
                                            <option value="">Selecione...</option>
                                            <option value="MATERIAL">MATERIAL</option>
                                            <option value="SERVIÇO">SERVIÇO</option>
                                        </select>
                                    </div>
                                    <div className='col-md-12 input-group-sm'>
                                        <Link to="/listas" className='btn btn-secondary mx-1'><i className="bi bi-arrow-left" /> Voltar</Link>
                                        <button type='submit' className='btn btn-success mx-1'><i className="bi bi-check-lg" /> Salvar</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </main>
                </ResponsiveShadow>
            </div>
        </div>
    );
}

export default Lista;
