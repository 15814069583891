import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './css/Cliente.css';
import axios from 'axios';
import Navbar from './components/Navbar';
import { API_BASE_URL } from './ApiConfig';
import ResponsiveShadow from './components/ResponsiveShadow';

const CHAR_LIMITS = {
    objeto: 500,
    justificativaPrioridade: 500,
    justificativaDemanda: 2000
};

function CatmatDemanda() {
    const [Dfd, setDfd] = useState({
        cnpj: '',
        dfd: '',
        dataCriacao: '',
        dataConclusao: '',
        requisitante: '',
        objeto: '',
        prioridade: '',
        justificativaPrioridade: '',
        justificativaDemanda: '',
        tipo: '',
        usuario: ''
    });
    const [Requisitantes, setRequisitantes] = useState([]);
    const [charCounts, setCharCounts] = useState({
        objeto: 0,
        justificativaPrioridade: 0,
        justificativaDemanda: 0
    });

    const token = localStorage.getItem('token');
    const location = useLocation();
    const descricaoRef = useRef(null);
    const justificativaPrioridadeRef = useRef(null);
    const justificativaDemandaRef = useRef(null);

    useEffect(() => {
        const storedCnpj = localStorage.getItem('cnpj');
        if (location.state && location.state.dfd) {
            setDfd(prevDfd => ({
                ...location.state.dfd,
                cnpj: storedCnpj || location.state.dfd.cnpj,
            }));

            // console.log("Data de Criação que está vindo do banco: " + location.state.dfd.dataCriacao);
            // console.log("Data de Conclusão que está vindo do banco: " + location.state.dfd.dataConclusao);
        } else {
            setDfd(prevDfd => ({
                ...prevDfd,
                cnpj: storedCnpj || '',
            }));
        }

        axios.get(`${API_BASE_URL.REQUISITANTE}/all_cnpj`, {
            params: { cnpj: storedCnpj },
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(result => {
            setRequisitantes(result.data);
        }).catch(error => {
            console.error('Erro ao obter listas:', error);
        });
    }, [location.state]);

    useEffect(() => {
        setCharCounts({
            objeto: Dfd.objeto.length,
            justificativaPrioridade: Dfd.justificativaPrioridade.length,
            justificativaDemanda: Dfd.justificativaDemanda.length
        });
    }, [Dfd]);

    function handleChange(event) {
        setDfd({ ...Dfd, [event.target.name]: event.target.value });
    }

    function handleDescricaoInput(event) {
        const { name, value, selectionStart } = event.target;
        // const newValue = value.toUpperCase().slice(0, CHAR_LIMITS[name]); // Limitar o valor ao número máximo de caracteres
        const newValue = value.slice(0, CHAR_LIMITS[name]);

        setDfd(prevState => ({
            ...prevState,
            [name]: newValue
        }));

        setCharCounts(prevCounts => ({
            ...prevCounts,
            [name]: newValue.length
        }));

        setTimeout(() => {
            if (name === 'objeto') {
                descricaoRef.current.selectionStart = selectionStart;
                descricaoRef.current.selectionEnd = selectionStart;
            } else if (name === 'justificativaPrioridade') {
                justificativaPrioridadeRef.current.selectionStart = selectionStart;
                justificativaPrioridadeRef.current.selectionEnd = selectionStart;
            } else if (name === 'justificativaDemanda') {
                justificativaDemandaRef.current.selectionStart = selectionStart;
                justificativaDemandaRef.current.selectionEnd = selectionStart;
            }
        }, 0);
    }

    function handleSubmit(event) {
        event.preventDefault();
        // console.log("Data de Criação que está sendo salva: " + Dfd.dataCriacao);
        // console.log("Data de Conclusão que está sendo salva: " + Dfd.dataConclusao);
        axios.post(`${API_BASE_URL.CATMAT_DFD}/save`,
            Dfd
            , {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(result => {
                window.location.href = '/demandas_catmat';
            }).catch(error => {
                console.error('Erro ao salvar demanda:', error);
            });
    }

    return (
        <div className='full-screen-div'>
            <Navbar />
            <div className="container mt-4 pt-5">
                <ResponsiveShadow className="container mt-4 mb-4">
                    <main role="main" className="col-md-12 ml-sm-auto col-lg-12 px-md-4">
                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                            <h3 className="h3">Cadastro de Demanda CATMAT</h3>
                        </div>
                        <div className="col-md-12 ml-sm-auto">
                            <form onSubmit={handleSubmit}>
                                <div className='row g-3'>
                                    {/* <div className={`col-md-${Dfd.num ? '3' : '4'} input-group-sm`}> */}
                                    {/* <div className='col-md-3 input-group-sm'>
                                    <label className='form-label'>CNPJ</label>
                                    <input onChange={handleChange} value={Dfd.cnpj} name='cnpj' type='text' className='form-control' disabled />
                                </div>    */}
                                    <div className='col-md-3 input-group-sm'>
                                        <label className='form-label'>Data de Criação</label>
                                        {/* <input onChange={handleChange} defaultValue={formatDateForInput(Dfd.dataCriacao)} name='dataCriacao' type='date' id='dataCriacao' className='form-control' /> */}
                                        <input onChange={handleChange} defaultValue={Dfd.dataCriacao} name='dataCriacao' type='date' id='dataCriacao' className='form-control' />
                                    </div>
                                    <div className='col-md-3 input-group-sm'>
                                        <label className='form-label'>Data de Conclusão</label>
                                        {/* <input onChange={handleChange} defaultValue={formatDateForInput(Dfd.dataConclusao)} name='dataConclusao' type='date' id='dataConclusao' className='form-control' /> */}
                                        <input onChange={handleChange} defaultValue={Dfd.dataConclusao} name='dataConclusao' type='date' id='dataConclusao' className='form-control' />
                                    </div>
                                    <div className='col-md-3 input-group-sm'>
                                        <label className='form-label'>Tipo</label>
                                        <select className='form-select' value={Dfd.tipo} onChange={handleChange} name='tipo'>
                                            <option value="">Selecione...</option>
                                            <option value="MATERIAL">MATERIAL</option>
                                            {/* <option value="SERVIÇO">SERVIÇO</option> */}
                                        </select>
                                    </div>
                                    <div className='col-md-3 input-group-sm'>
                                        <label className='form-label'>Área Requisitante</label>
                                        <select className='form-select' value={Dfd.requisitante} onChange={handleChange} name='requisitante'>
                                            <option value="">Selecione...</option>
                                            {Requisitantes.map((option, index) => (
                                                <option key={index} value={option.nome}>
                                                    {option.nome}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className='col-md-12 input-group-sm'>
                                        <label className='form-label'>Descrição do Objeto ({charCounts.objeto}/{CHAR_LIMITS.objeto})</label>
                                        <textarea className="form-control" rows="3" ref={descricaoRef} onInput={handleDescricaoInput} value={Dfd.objeto} name='objeto' />
                                    </div>
                                    <div className='col-md-4 input-group-sm'>
                                        <label className='form-label'>Prioridade</label>
                                        <select className='form-select' value={Dfd.prioridade} onChange={handleChange} name='prioridade'>
                                            <option value="">Selecione...</option>
                                            <option value="BAIXA">BAIXA</option>
                                            <option value="MÉDIA">MÉDIA</option>
                                            <option value="ALTA">ALTA</option>
                                        </select>
                                    </div>
                                    {/* {Dfd.prioridade === "ALTA" && ()} */}
                                    <div className='col-md-12 input-group-sm'>
                                        <label className='form-label'>
                                            Justificativa da Prioridade ({charCounts.justificativaPrioridade}/{CHAR_LIMITS.justificativaPrioridade})
                                        </label>
                                        <textarea
                                            className="form-control"
                                            rows="3"
                                            ref={justificativaPrioridadeRef}
                                            onInput={handleDescricaoInput}
                                            value={Dfd.justificativaPrioridade}
                                            name='justificativaPrioridade'
                                        />
                                    </div>

                                    <div className='col-md-12 input-group-sm'>
                                        <label className='form-label'>Justificativa da Demanda ({charCounts.justificativaDemanda}/{CHAR_LIMITS.justificativaDemanda})</label>
                                        <textarea className="form-control" rows="3" ref={justificativaDemandaRef} onInput={handleDescricaoInput} value={Dfd.justificativaDemanda} name='justificativaDemanda' />
                                    </div>
                                    <div className='col-md-12 mb-3 input-group-sm'>
                                        <Link to="/demandas_catmat" className='btn btn-secondary mx-1'><i className="bi bi-arrow-left" /> Voltar</Link>
                                        <button type='submit' className='btn btn-success mx-1'><i className="bi bi-check-lg" /> Salvar</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </main>
                </ResponsiveShadow>
            </div>
        </div>
    );
}

export default CatmatDemanda;
