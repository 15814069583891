import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './css/Cliente.css';
import axios from 'axios';
import Navbar from './components/Navbar';
import { API_BASE_URL } from './ApiConfig';
import 'bootstrap-icons/font/bootstrap-icons.css';
import ResponsiveShadow from './components/ResponsiveShadow';

function ListaItens() {
    const [MatLista, setMatLista] = useState({ cnpj: '', id: '', data: '', descricao: '', tipo: '' });
    const [MatListaItem, setMatListaItem] = useState([]);
    const [itemToDelete, setItemToDelete] = useState(null);
    const location = useLocation();
    const token = localStorage.getItem('token');

    useEffect(() => {
        if (location.state && location.state.matLista) {
            setMatLista(location.state.matLista);
            console.log("LISTA: ", location.state.matLista);
        }
        if (MatLista.cnpj !== '') {
            axios.get(`${API_BASE_URL.LISTA_ITEM}/mat_lista`, {
                params: {
                    cnpj: MatLista.cnpj,
                    id: MatLista.id
                },
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(response => {
                if (response.status === 200) {
                    setMatListaItem(response.data);
                } else {
                    console.error('Ocorreu um erro ao buscar os dados:', response.statusText);
                }
            })
                .catch(error => {
                    console.error('Ocorreu um erro ao buscar os dados:', error);
                });
        }
    }, [location.state, MatLista]);

    function handleDeleteConfirm() {
        axios.delete(`${API_BASE_URL.LISTA_ITEM}/delete`, {
            params: {
                cnpj: itemToDelete.cnpj,
                id: itemToDelete.id,
                grupo: itemToDelete.grupo,
                classe: itemToDelete.classe,
                codigo: itemToDelete.codigo
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(result => {
                setMatListaItem(MatListaItem.filter(item => item.id !== itemToDelete.id ||
                    item.cnpj !== itemToDelete.cnpj ||
                    item.grupo !== itemToDelete.grupo ||
                    item.classe !== itemToDelete.classe ||
                    item.codigo !== itemToDelete.codigo));
                setItemToDelete(null);
            })
            .catch(error => {
                console.error('Erro ao excluir o item:', error);
            });
    }

    function handleDelete(item) {
        setItemToDelete(item);
        const modal = new window.bootstrap.Modal(document.getElementById('deleteConfirmationModal'));
        modal.show();
    }

    function formatDateForInput(dateString) {
        if (dateString) {
            const date = new Date(dateString);
            const formattedDate = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;
            return formattedDate;
        }
        return '';
    }

    return (
        <div className='full-screen-div'>
            <Navbar />
            <div className="container mt-4 pt-5">
                <ResponsiveShadow className="container mt-4 mb-4">
                    <main role="main" className="col-md-12 ml-sm-auto col-lg-12 px-md-4">
                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-1 mb-2 border-bottom">
                            <h3 className="h3">Listas de Materiais ou Serviços</h3>
                        </div>
                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-1 pb-2 mb-1">
                            <label className='form-label'><b>Data:</b> {formatDateForInput(MatLista.data)} - <b>Descrição:</b> {MatLista.descricao}</label>
                        </div>
                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-1 pb-2 mb-1">
                            <div className='col-md-12 input-group-sm'>
                                <Link to="/listas" className='btn btn-secondary btn-sm'>
                                    <i className="bi bi-arrow-left" /> Voltar</Link>&nbsp;
                                <Link to="/item_find" state={{ matLista: MatLista }} className='btn btn-success btn-sm' >
                                    <i className="bi bi-plus-lg" /> Adicionar Item</Link>
                            </div>
                        </div>
                        <div className="table-responsive-sm">
                            <table className="table table-hover table-sm">
                                <thead className="table-secondary">
                                    <tr>
                                        <th scope="col">Grupo</th>
                                        <th scope="col">Classe</th>
                                        <th scope="col">Código</th>
                                        <th scope="col">Descrição</th>
                                        <th scope="col">Ações</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {MatListaItem.map(c => (
                                        <tr key={`${c.cnpj}-${c.id}-${c.grupo}-${c.classe}-${c.codigo}`}>
                                            <td>{c.grupo}</td>
                                            <td>{c.classe}</td>
                                            <td>{c.codigo}</td>
                                            <td>{c.matItemDescricao.descricao}</td>
                                            <td>
                                                <button onClick={() => handleDelete(c)} className='btn btn-danger btn-sm'>
                                                    <i className="bi bi-trash3" /> Excluir
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </main>
                </ResponsiveShadow>
            </div>
            {/* Modal de Confirmação */}
            <div className="modal fade" id="deleteConfirmationModal" tabIndex="-1" aria-labelledby="deleteConfirmationModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="deleteConfirmationModalLabel">Confirmar Exclusão</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            Tem certeza que deseja excluir este item?
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                            <button type="button" className="btn btn-danger" onClick={handleDeleteConfirm} data-bs-dismiss="modal">Excluir</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ListaItens;
