import React, { forwardRef } from 'react';
import InputMask from 'react-input-mask';

const CNPJMaskInput = forwardRef(({ value, onChange, ...rest }, ref) => {
    return (
        <InputMask
            mask="99.999.999/9999-99"
            value={value}
            onChange={onChange}
        >
            {(inputProps) => <input {...inputProps} {...rest} ref={ref} className="form-control" />}
        </InputMask>
    );
});

export default CNPJMaskInput;
