import React from 'react';

function Pagination({ totalItems, rowsPerPage, startIndex, onRowsPerPageChange, onFirstItems, onPreviousItems, onNextItems, onLastItems }) {
    const endIndex = startIndex + rowsPerPage;

    return (
        <div className="d-flex flex-wrap justify-content-center align-items-center mb-3 small">
            <div className="d-flex flex-wrap align-items-center mb-3">
                <div className="me-2 mb-2 mb-md-0 d-flex align-items-center">
                    <label htmlFor="rowsPerPageSelect" className="form-label me-2 mb-1 text-nowrap">Registros por página:</label>
                    <select
                        id="rowsPerPageSelect"
                        className="form-select form-select-sm"
                        value={rowsPerPage}
                        onChange={onRowsPerPageChange}
                    >
                        <option value={5}>05</option>
                        <option value={10}>10</option>
                        <option value={30}>30</option>
                        <option value={totalItems}>Todos</option>
                    </select>
                </div>
                <button
                    className="btn btn-sm btn-outline-primary me-1 mb-1 mb-md-0"
                    onClick={onFirstItems}
                    disabled={startIndex === 0}
                >
                    Primeiros
                </button>
                <button
                    className="btn btn-sm btn-outline-primary me-1 mb-1 mb-md-0"
                    onClick={onPreviousItems}
                    disabled={startIndex === 0}
                >
                    Anterior
                </button>
                <span className="mx-2 mb-1 mb-md-0">{startIndex + 1} - {Math.min(endIndex, totalItems)} de {totalItems}</span>
                <button
                    className="btn btn-sm btn-outline-primary ms-1 mb-1 mb-md-0"
                    onClick={onNextItems}
                    disabled={endIndex >= totalItems}
                >
                    Próximo
                </button>
                <button
                    className="btn btn-sm btn-outline-primary ms-1 mb-1 mb-md-0"
                    onClick={onLastItems}
                    disabled={endIndex >= totalItems}
                >
                    Últimos
                </button>
            </div>
        </div>
    );
}

export default Pagination;
