import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

function NavbarEmpty() {
    const [user, setUser] = useState(null);
    const navigate = useNavigate();
    
    useEffect(() => {
        if (localStorage.getItem('token') === null) {
            navigate('/');
        }
        // Recupera os dados do usuário do localStorage
        const userData = localStorage.getItem('user');
        if (userData) {
            setUser(JSON.parse(userData));
        } else {
            // Se não houver dados do usuário, redireciona para a página de login
            navigate('/');
        }
    }, [navigate]);
    if (!user) {
        return <div>Loading...</div>;
    }
    return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
            <div className="container-fluid">
                <img src="/assets/logo.png" alt="Logo" width="100" height="50" className="d-inline-block align-top rounded-3"/>

                {/* <a className="navbar-brand" href="#">Catálogo</a> */}
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav ms-auto">
                        <div>
                        {user.nome && <div className="nav-link compact" style={{ fontSize: '0.8em' }}>{user.nome}</div>}
                        </div>
                    </ul>

                </div>

            </div>
        </nav>

    );
}

export default NavbarEmpty;