import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_BASE_URL } from './ApiConfig';
import Navbar from './components/Navbar';
import ResponsiveShadow from './components/ResponsiveShadow';

const PreencherTemplate = () => {
    const [template, setTemplate] = useState('');
    const [campos, setCampos] = useState({});
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [appCliente, setAppCliente] = useState(null);
    const navigate = useNavigate();
    const token = localStorage.getItem('token');

    useEffect(() => {
        if (!token) {
            navigate('/'); // Redireciona para a página inicial se o token não estiver presente
        }

        const appClienteData = localStorage.getItem('appCliente');
        if (appClienteData) {
            setAppCliente(JSON.parse(appClienteData));
        }
    }, [navigate, token]);

    const handleNomeTemplateChange = (e) => {
        setTemplate(e.target.value);
    };

    const handleCampoChange = (e) => {
        const { name, value } = e.target;
        setCampos((prevCampos) => ({ ...prevCampos, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!template || Object.keys(campos).length === 0) {
            setMessage('Por favor, forneça o nome do template e preencha os campos.');
            return;
        }

        if (!appCliente) {
            setMessage('Erro: Informações do cliente não encontradas.');
            return;
        }

        setLoading(true);

        try {
            const formData = new FormData();
            formData.append('cnpj', appCliente.cnpj);
            formData.append('template', template);

            // Adiciona os campos como um objeto JSON serializado
            formData.append('campos', JSON.stringify(campos));

            const response = await axios.post(
                // `${API_BASE_URL.TEMPLATE}/preencherTemplateBD`,
                `${API_BASE_URL.TEMPLATE}/preencherWord`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                    responseType: 'blob',
                }
            );
            const blob = new Blob([response.data], {
                type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `${template}_preenchido.docx`;
            link.click();

            setMessage('Documento preenchido com sucesso!');
        } catch (error) {
            console.error('Erro ao preencher o template:', error);
            setMessage('Erro ao preencher o template.');
        } finally {
            setLoading(false);
        }
    };



    return (
        <div className='full-screen-div'>
            <Navbar />
            <div className="container mt-4 pt-5">
                <ResponsiveShadow className="container mt-4 mb-4">
                    <div className="d-flex justify-content-between align-items-center border-bottom mb-4">
                        <h3>Preencher Template</h3>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label className='form-label'>Nome do Template:</label>
                            <input
                                className='form-control'
                                type="text"
                                value={template}
                                onChange={handleNomeTemplateChange}
                            />
                        </div>
                        <div className="mb-3">
                            <label className='form-label'>Campo 1 (Ex: nome):</label>
                            <input
                                className='form-control'
                                type="text"
                                name="nome"
                                onChange={handleCampoChange}
                            />
                        </div>
                        <div className="mb-3">
                            <label className='form-label'>Campo 2 (Ex: endereço):</label>
                            <input
                                className='form-control'
                                type="text"
                                name="endereco"
                                onChange={handleCampoChange}
                            />
                        </div>
                        {/* Adicione mais campos conforme necessário */}
                        <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={loading}
                        >
                            {loading ? 'Gerando...' : 'Gerar Documento'}
                        </button>
                    </form>
                    {message && <p className="mt-3">{message}</p>}
                </ResponsiveShadow>
            </div>
        </div>
    );
};

export default PreencherTemplate;
