// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html, body {
    margin: 0;
    padding: 0;
    height: 100vh;
    background-color: rgb(250, 250, 250); /* Cor de fundo para visualização */
}
.full-screen-div {
  height: 100vh; /* Altura total da tela */
  display: flex; /* Flexbox para estender a altura dos filhos */
  flex-direction: column; /* Organiza os filhos em coluna */
}
.container-fluid, .row {
    height: 100%;
  }

  /* Para o Sidebar */
.sidebar {
  height: 100%;
  transition: width 0.3s ease;
}

/* Para colapsar o Sidebar */
.sidebar.collapsed {
  width: 0;
  overflow: hidden;
}

/* Para esconder o conteúdo principal quando o Sidebar estiver colapsado */
.container-flex.sidebar-collapsed .col-md-10 {
  margin-left: 0;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/service/css/Item.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,UAAU;IACV,aAAa;IACb,oCAAoC,EAAE,mCAAmC;AAC7E;AACA;EACE,aAAa,EAAE,yBAAyB;EACxC,aAAa,EAAE,8CAA8C;EAC7D,sBAAsB,EAAE,iCAAiC;AAC3D;AACA;IACI,YAAY;EACd;;EAEA,mBAAmB;AACrB;EACE,YAAY;EACZ,2BAA2B;AAC7B;;AAEA,4BAA4B;AAC5B;EACE,QAAQ;EACR,gBAAgB;AAClB;;AAEA,0EAA0E;AAC1E;EACE,cAAc;EACd,WAAW;AACb","sourcesContent":["html, body {\r\n    margin: 0;\r\n    padding: 0;\r\n    height: 100vh;\r\n    background-color: rgb(250, 250, 250); /* Cor de fundo para visualização */\r\n}\r\n.full-screen-div {\r\n  height: 100vh; /* Altura total da tela */\r\n  display: flex; /* Flexbox para estender a altura dos filhos */\r\n  flex-direction: column; /* Organiza os filhos em coluna */\r\n}\r\n.container-fluid, .row {\r\n    height: 100%;\r\n  }\r\n\r\n  /* Para o Sidebar */\r\n.sidebar {\r\n  height: 100%;\r\n  transition: width 0.3s ease;\r\n}\r\n\r\n/* Para colapsar o Sidebar */\r\n.sidebar.collapsed {\r\n  width: 0;\r\n  overflow: hidden;\r\n}\r\n\r\n/* Para esconder o conteúdo principal quando o Sidebar estiver colapsado */\r\n.container-flex.sidebar-collapsed .col-md-10 {\r\n  margin-left: 0;\r\n  width: 100%;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
