import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_BASE_URL } from './ApiConfig';
import Navbar from './components/Navbar';
import Pagination from './components/Pagination';
import ConfirmDeleteModal from './components/ConfirmDeleteModal';
import SuccessModal from './components/SuccessModal';
import ResponsiveShadow from './components/ResponsiveShadow';

const UploadImagem = () => {
    const [file, setFile] = useState(null);
    const [orientacao, setOrientacao] = useState('');
    const [templates, setTemplates] = useState([]);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [templateToDelete, setTemplateToDelete] = useState(null);

    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [startIndex, setStartIndex] = useState(0);
    const [appCliente, setAppCliente] = useState(null);

    const navigate = useNavigate();
    const token = localStorage.getItem('token');
    const fileInputRef = useRef(null);

    // Inicialização
    useEffect(() => {
        const appClienteData = localStorage.getItem('appCliente');
        if (!token) {
            navigate('/');
        } else if (appClienteData) {
            setAppCliente(JSON.parse(appClienteData));
        }
    }, [navigate, token]);

    useEffect(() => {
        if (appCliente) {
            fetchTemplates();
        }
    }, [appCliente]);

    const fetchTemplates = async () => {
        if (!appCliente?.cnpj) {
            console.error("CNPJ não encontrado em appCliente.");
            return;
        }
        try {
            const response = await axios.get(`${API_BASE_URL.APP_IMAGES}/list/${appCliente.cnpj}`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setTemplates(response.data);
        } catch (error) {
            console.error('Erro ao buscar os templates:', error);
        }
    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile && selectedFile.type === "image/png") {
            setFile(selectedFile);
        } else {
            setFile(null);
            showAlert("Por favor, selecione um arquivo PNG.");
        }
    };

    const handleOrientacaoChange = (e) => setOrientacao(e.target.value);

    const handleUpload = async (e) => {
        e.preventDefault();
        if (!file || !orientacao) {
            showAlert('Por favor, selecione a orientação e um arquivo.');
            return;
        }

        const formData = new FormData();
        formData.append('file', file);
        formData.append('orientacao', orientacao);
        formData.append('cnpj', appCliente.cnpj);

        try {
            const response = await axios.post(`${API_BASE_URL.APP_IMAGES}/save`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`
                },
            });
            showAlert(response.data);
            resetUploadForm();
            fetchTemplates();
        } catch (error) {
            console.error('Erro ao enviar o template:', error);
            showAlert('Erro ao enviar o template.');
        }
    };

    const handleDeleteClick = (template) => {
        setTemplateToDelete(template);
        setShowDeleteModal(true);
    };

    const handleCancelDelete = () => {
        setShowDeleteModal(false);
        setTemplateToDelete(null);
    };

    const handleDeleteConfirm = async () => {
        if (templateToDelete) {
            try {
                await axios.delete(`${API_BASE_URL.APP_IMAGES}/delete`, {
                    params: {
                        cnpj: templateToDelete.cnpj,
                        orientacao: templateToDelete.orientacao
                    },
                    headers: { Authorization: `Bearer ${token}` }
                });
                showAlert('Template excluído com sucesso.');
                fetchTemplates();
            } catch (error) {
                console.error('Erro ao excluir o template:', error);
                showAlert('Erro ao excluir o template.');
            } finally {
                setShowDeleteModal(false);
                setTemplateToDelete(null);
            }
        }
    };

    const showAlert = (message) => {
        setSuccessMessage(message);
        setShowSuccessModal(true);
    };

    const resetUploadForm = () => {
        setOrientacao('');
        setFile(null);
        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }
    };

    const endIndex = startIndex + rowsPerPage;
    const selectedDemandas = templates.slice(startIndex, endIndex);
    const totalItems = templates.length;

    return (
        <div className='full-screen-div'>
            <Navbar />
            <div className="container mt-4 pt-5">
                <ResponsiveShadow className="container mt-4 mb-4">
                    <main role="main" className="col-md-12 ml-sm-auto col-lg-12 px-md-4">
                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                            <h3 className="h3">Envio de Imagens</h3>
                        </div>
                        <div className="col-md-6 ml-sm-auto">
                            <form onSubmit={handleUpload}>
                                <div className='col-md-12 input-group-sm'>
                                    <label className='form-label'>Orientação da Imagem</label>
                                    <select onChange={handleOrientacaoChange} value={orientacao} name='orientacao' className='form-select'>
                                        <option value="">Selecione...</option>
                                        <option value="P">PAISAGEM</option>
                                        <option value="R">RETRATO</option>
                                    </select>
                                </div>
                                <div className='col-md-12 mt-1 input-group-sm'>
                                    <label className='form-label'>Selecione o Arquivo</label>
                                    <input
                                        className='form-control'
                                        type="file"
                                        onChange={handleFileChange}
                                        name="file"
                                        ref={fileInputRef}
                                        accept="image/png"
                                    />
                                </div>
                                <div className="form-group mb-3 mt-2">
                                    <Link className="btn btn-secondary btn-sm me-1" to="/home">
                                        <i className="bi bi-house" />
                                    </Link>
                                    <button className='btn btn-success btn-sm mx-1' type="submit">Enviar</button>
                                </div>
                            </form>
                        </div>
                        <div className="col-md-6 ml-sm-auto mt-4">
                            <div className="table table-responsive">
                                <h5 className="h5">Lista de Templates</h5>
                                <table className="table table-striped table-bordered table-hover">
                                    <thead>
                                        <tr>
                                            <th>Orientação</th>
                                            <th>Ações</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {selectedDemandas.length > 0 ? (
                                            selectedDemandas.map((template, index) => (
                                                <tr key={index}>
                                                    <td>{template.orientacao === 'P' ? 'PAISAGEM' : 'RETRATO'}</td>
                                                    <td>
                                                        <button
                                                            className="btn btn-danger btn-sm"
                                                            onClick={() => handleDeleteClick(template)}
                                                        >
                                                            <i className="bi bi-trash3 me-1" /> Excluir
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="3" className="text-center">Nenhum template encontrado.</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                                {/* <Pagination
                            totalItems={totalItems}
                            rowsPerPage={rowsPerPage}
                            startIndex={startIndex}
                            onRowsPerPageChange={(e) => setRowsPerPage(Number(e.target.value))}
                            onFirstItems={() => setStartIndex(0)}
                            onPreviousItems={() => setStartIndex(Math.max(startIndex - rowsPerPage, 0))}
                            onNextItems={() => setStartIndex(Math.min(startIndex + rowsPerPage, totalItems - rowsPerPage))}
                            onLastItems={() => setStartIndex(Math.max(totalItems - rowsPerPage, 0))}
                        /> */}
                            </div>
                        </div>

                        <SuccessModal
                            show={showSuccessModal}
                            onClose={() => setShowSuccessModal(false)}
                            message={successMessage}
                        />

                        <ConfirmDeleteModal
                            show={showDeleteModal}
                            onClose={() => setShowDeleteModal(false)}
                            onConfirm={handleDeleteConfirm}
                        />
                    </main>
                </ResponsiveShadow>
            </div>
        </div>
    );
};

export default UploadImagem;
