// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-select-width {
    width: 500px; /* Ajuste a largura conforme necessário */
}

.shadow-container {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Sombra suave */
    padding: 20px;
    max-width: 500px;
    width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/service/css/PosLogin.css"],"names":[],"mappings":"AAAA;IACI,YAAY,EAAE,yCAAyC;AAC3D;;AAEA;IACI,uBAAuB;IACvB,kBAAkB;IAClB,0CAA0C,EAAE,iBAAiB;IAC7D,aAAa;IACb,gBAAgB;IAChB,WAAW;AACf","sourcesContent":[".custom-select-width {\r\n    width: 500px; /* Ajuste a largura conforme necessário */\r\n}\r\n\r\n.shadow-container {\r\n    background-color: white;\r\n    border-radius: 8px;\r\n    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Sombra suave */\r\n    padding: 20px;\r\n    max-width: 500px;\r\n    width: 100%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
