const BASE_URL = "https://gcat.ijservicosti.com.br/gcat";
export const API_BASE_URL = {
  LOGIN: `${BASE_URL}/auth`,
  SIGNUP: `${BASE_URL}/auth/signup`,
  CLIENTE: `${BASE_URL}/app_cliente`,
  ITEM: `${BASE_URL}/mat_item_descricao`,
  CATMAT_ITEM: `${BASE_URL}/catmat_item`,
  CATMAT_DFD: `${BASE_URL}/catmat_dfd`,
  CATMAT_DFD_ITEM: `${BASE_URL}/catmat_dfd_item`,
  LISTA: `${BASE_URL}/mat_lista`,
  LISTA_ITEM: `${BASE_URL}/mat_lista_item`,
  DFD: `${BASE_URL}/dfd`,
  DFD_ITEM: `${BASE_URL}/dfd_item`,
  USUARIO: `${BASE_URL}/usuario`,
  USUARIO_BIND: `${BASE_URL}/usuario_bind`,
  REQUISITANTE: `${BASE_URL}/requisitante`,
  UF: `${BASE_URL}/uf`,
  MUNICIPIO: `${BASE_URL}/municipio`,
  UNIDADE_MEDIDA: `${BASE_URL}/unidade_medida`,
  UNIDADE_CATSER: `${BASE_URL}/unidade_catser`,
  TEMPLATE: `${BASE_URL}/template`,
  TEMPLATE_DEFAULT: `${BASE_URL}/template_default`,
  APP_IMAGES: `${BASE_URL}/app_images`,
  CATSER_ITEM: `${BASE_URL}/catser_item`,
  CATSER_DFD: `${BASE_URL}/catser_dfd`,
  CATSER_DFD_ITEM: `${BASE_URL}/catser_dfd_item`,
};
